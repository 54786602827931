// Rules:
// 1. Masukan keyword sesuai alphabet, gunakan https://codeshack.io/json-sorter/ (copas bagian translation lalu sort by key name)
// 2. Pastikan keyword yang baru ada di 2 tempat (id dan en)
const resLang = {
    "id": {
        "translation": {
            "academy": "Academy",
            "account_number": "Nomor Rekening",
            "actions": "Aksi",
            "actual_cbm": "CBM Aktual",
            "actual_weight": "Berat Aktual",
            "add": "Tambah",
            "add_file": "Tambah Berkas",
            "add_new_address": "Tambah Alamat Baru",
            "add_to_cart": "Tambahkan Ke Keranjang",
            "additional_fee": "Biaya Tambahan",
            "additional_file": "Berkas Tambahan",
            "additional_request_fragile_info": "Ada Tambahan Biaya Pengemasan Tambahan, Terutama Untuk Barang yang Mudah Rusak",
            "additional_shipping_fee_info": "Ada Kemungkinan Biaya Pengiriman Tambahan Dari Penjual Ke Gudang Kami Di Luar Negeri",
            "address": "Alamat",
            "address_label": "Label Alamat",
            "admin_file": "Berkas Admin",
            "admin_note": "Catatan Admin",
            "advance_class": "Kelas Lanjutan",
            "air": "UDARA",
            "all": "Semua",
            "all_product": "Semua Produk",
            "all_right_reserved": "Semua Hak Cipta Dilindungi Undang-Undang",
            "already_have_product": "Telah Memiliki Produk",
            "already_know": "Sudah Tahu Apa Itu",
            "anteraja_info": "Untuk Pilihan Anteraja Hanya Diatas 1 Kg Dan Kurang Dari 50 Kg. Pastikan Kode Pos Anda Pada Alamat Tujuan Ada.",
            "apply_cbm_balance": "Terapkan Saldo CBM",
            "apply_promo": "Terapkan Kode Promo",
            "approve": "Menyetujui",
            "approved_at": "Tanggal Disetujui",
            "back": "Kembali",
            "balance_type": "Tipe Saldo",
            "bank_code": "Kode Bank",
            "battery": "Batre",
            "beginner_class": "Kelas Pemula",
            "bill_detail": "Detail Tagihan",
            "bill_title": "Judul Tagihan",
            "birth": "Lahir",
            "bm": "BM",
            "buy_again": "Beli Lagi",
            "buy_now": "Beli Sekarang",
            "buy_package": "Beli Paket",
            "buyer": "Pembeli",
            "calculate": "Hitung",
            "calculate_result": "Hasil Perhitungan",
            "calculator": "Calculator",
            "cancel": "Batalkan",
            "cancel_cbm_balance": "Batalkan Saldo CBM",
            "cancel_payment": "Batalkan Pembayaran",
            "cancel_promo": "Batalkan Kode Promo",
            "cancel_voucher": "Batalkan Voucher",
            "cart": "Keranjang",
            "catalog": "Catalog",
            "category": "Kategori",
            "cbm_amount": "Jumlah CBM",
            "cbm_balance": "Saldo CBM",
            "cbm_balance_form": "Formulir Saldo CBM",
            "cbm_package": "Paket CBM",
            "change": "Ubah",
            "change_address": "Ganti Alamat",
            "change_password": "Ubah Kata Sandi",
            "chat_to_admin": "Percakapan Ke Admin",
            "checkout": "Pembayaran",
            "choose_address": "Pilih Alamat",
            "choose_bank": "Pilih Bank",
            "choose_district_or_city_placeholder": "-- Pilih Kabupaten / Kecamatan --",
            "choose_mode": "Pilih Mode",
            "choose_voucher": "Pilih Voucher",
            "city": "Kota / Kabupaten",
            "click": "Klik",
            "click_for_detail": "Klik Untuk Informasi Lebih Detail",
            "click_here_now": "Klik Di sini Sekarang",
            "click_to_use_promo_code": "Klik Tombol Dibawah Ini Untuk Menggunakan Kode Promo",
            "close": "Tutup",
            "coming_soon": "Segera Hadir",
            "commercial_invoice_file": "Berkas Faktur Komersil",
            "company": "Perusahaan",
            "complaint_deadline": "Jika Ada Keluhan Mengenai Produk Anda, Silakan Klaim Dalam Waktu 3 Hari Kerja Setelah Menerima. Kirimkan Keluhan Anda Bersama Dengan Video dan Gambar Pembongkaran Melalui Nomor WhatsApp Resmi IMPORTIR.COM +6287800174596",
            "confirm": "Konfirmasi",
            "confirmation_message": "Pesan Konfirmasi",
            "confused_about_cogs": "Bingung Menentukan Jenis Produk Dan Perhitungan HPP-nya ? Admin Kami Akan Menemukannya Untuk Anda !",
            "consultation": "Consultation",
            "contact_us": "Contact Us",
            "copy": "Salin",
            "cost_info": "Informasi Biaya",
            "country": "Negara",
            "create_order": "Buat Pesanan",
            "create_shipping": "Buat Shipping",
            "created_at": "Tanggal Pembuatan",
            "crowdfund": "Crowdfund",
            "crowdfund_feature": "Fitur Crowdfund",
            "crowdfund_feature_exp": "Fitur Crowdfund Menyediakan Berbagai Pilihan Produk Yang Dapat Anda Gunakan Sebagai Referensi Atau Daftar Untuk Produk Penjualan Anda Tanpa Harus Khawatir Dengan Hs Code, Biaya Pengiriman, Dan Pajak. Harga Produk Yang Ada Di Crowdfund Adalah Harga Akhir (HPP) Hingga Sampai Di Gudang Di Indonesia (Tidak Termasuk Ongkos Kirim Ke Alamat Anda).",
            "crowdfund_feature_list_1": "Dari Indonesia: Produk Yang Sudah Tersedia Di Indonesia Dan Siap Dikirim Ke Seluruh Indonesia.",
            "crowdfund_feature_list_2": "Dari Cina: Produk Yang Perlu Diimpor Dari China Akan Memakan Waktu 30-40 Hari Untuk Mencapai Alamat Anda.",
            "crowdfund_feature_list_title": "Ada (2) Dua Jenis Fitur Crowdfund:",
            "crowdfund_has_ended": "Produk Crowdfund Telah Berakhir",
            "customer_address": "Alamat Konsumen",
            "customer_complaint_service": "Layanan Pengaduan Konsumen",
            "data_not_found": "Data Tidak Ditemukan",
            "date": "Tanggal",
            "days": "Hari",
            "delete": "Hapus",
            "delivery_fee_local": "Biaya Pengiriman Lokal",
            "demo": "DEMONSTRASI",
            "demo_mode_info": "Mode Demo Hanya Digunakan Untuk Latihan. Transaksi Tidak Akan Dicek Oleh Admin",
            "description": "Deskripsi",
            "diff_alert": "Anda memiliki perbedaan tipe product (lartas / non lartas), silahkan buat tipe cart sama",
            "digital_invoice_policy": "Invoice Dibuat Di Komputer Dan Berlaku Tanpa Tanda Tangan Dan Stempel.",
            "discount": "Diskon",
            "district": "Kecamatan",
            "does_item_contain": "Apakah Barang Mengandung Hal Dibawah Ini?",
            "dont_have_history_wishlist": "Anda Tidak Memiliki Riwayat Daftar Keinginan Produk",
            "edit": "Ubah",
            "edit_profile": "Ubah Profil",
            "escrow_statuses": {
                "Admin Confirm": "Disetujui Admin",
                "Cancel": "Dibatalkan",
                "Customer Paid": "Telah Dibayar Konsumen",
                "Done": "Selesai",
                "customer ready": "Konsumen Sudah Siap"
            },
            "estimate": "Estimasi",
            "estimated_cbm": "CBM Estimasi",
            "estimated_info": "Ada Kemungkinan Biaya Tambahan Jika Berat Atau Volume Yang Anda Input Tidak Sesuai Dengan Berat/Volume Barang Yang Sebenarnya Saat Tiba Di Gudang",
            "estimated_price": "Harga Estimasi",
            "estimated_total_purchase": "Estimasti Total Pembelian",
            "estimated_weight": "Berat Estimasi",
            "exclusive_for_member": "Ekslusif Untuk Member",
            "expired_order_invoice": "Batas Waktu Pembayaran Pesanan Ini Sudah Habis, Hubungi Admin Untuk Informasi Lebih Lanjut",
            "file": "Berkas",
            "file_and_documents": "Berkas & Dokumen",
            "fill_in_my_purchase_request": "Isi Permintaan Pembelian Saya",
            "find_dream_product": "Isi Formulir Di Bawah Ini Untuk Menemukan Barang Impian Anda",
            "find_your_class": "Temukan kelas yang sesuai kebutuhan Anda",
            "first_bill_estimation": "Estimasi Tagihan Pertama",
            "form": "Formulir",
            "fragile": "Pecah Belah",
            "freight": "Pengiriman",
            "freight_desc": "Deskripsi Pengiriman",
            "from": "Dari",
            "full_address": "Alamat Lengkap",
            "get_additional_fee_info": "Dalam Prosesnya, Anda Mungkin Akan Mendapatkan Pemberitahuan Informasi Harga Tambahan Jika",
            "grand_total": "Total Keseluruhan",
            "handling_fee": "Biaya Penanganan",
            "history": "Riwayat",
            "home": "Home",
            "hours": "Jam",
            "hpp": "HPP",
            "i_agree_with_info": "Saya Setuju Dengan Informasi Detail",
            "id_image": "Foto KTP",
            "id_number": "Nomor KTP",
            "import_from": "Diimport Dari",
            "importir_news": "Berita Importir",
            "in_warehouse_indo_policy": "Apabila Barang Telah Sampai Di Gudang Indonesia Dan Dalam Waktu 30 (tiga puluh) Hari Kalender Pihak Pertama Tidak Melunasi Seluruh Biaya Yang Tertera Pada Invoice, Maka Barang Yang Dipesan Oleh Pihak Pertama Dianggap Hangus. Dengan demikian, Pihak Pertama Memberi Kuasa Kepada Pihak Kedua Atas Barang-Barang Tersebut.",
            "information_detail": "Detail Informasi",
            "input_balance_cbm": "Masukan Saldo CBM",
            "input_cbm": "Masukan CBM",
            "input_weight": "Masukan Berat",
            "invoice": "Faktur",
            "invoice_file": "Berkas Invoice",
            "invoice_history_list": "Invoice History List",
            "invoice_list": "Daftar Invoice",
            "invoice_number": "Nomor Invoice",
            "invoice_packing_list": "Daftar Invoice & Pengepakan",
            "invoice_unavailable": "Invoice Tidak Tersedia",
            "item": "Barang",
            "item_may_opened_info": "Dan Saya Setuju Jika Barang Saya Dibuka Karena Pemeriksaan Acak Oleh Petugas Bea dan Cukai",
            "additional_quality": "IMPORTIR.COM Tidak Bertanggung Jawab Atas Kualitas Produk Seperti Kondisi, Varian, Jenis, Warna, Model, Dan Pembelian Produk ACAK/Random",
            "additinal_free_ongkir": "Berlaku Gratis Ongkos Kirim Pengiriman lokal Indonesia JABODETABEK untuk kode order SHP. Kecuali, Biaya Helper, Biaya Packing Tambahan, Biaya Toll, Biaya Tunggu Kurir, dan Biaya Penggunaan Alat Berat (jika diperlukan). Tidak berlaku untuk order dengan Kode Promo dan Balance CBM",
            "job": "Pekerjaan",
            "last_status": "Status Terakhir",
            "latest_news": "Berita Terkini",
            "learn_now": "Belajar Sekarang",
            "lets_shop_now": "Yuk Belanja Sekarang",
            "liquid": "Cairan",
            "list_address": "Daftar Alamat",
            "list_sfy": "Daftar Source For You",
            "load_more": "Muat Lebih Banyak",
            "login_to_account": "Masuk Ke Akun Anda",
            "logistic": "Pengiriman",
            "main_account_number": "Nomor Rekening Utama",
            "max_upload": "Maksimal Ukuran Berkas Yang Bisa Diunggah Adalah 2 MB",
            "mbanking_trf_guide": "Petunjuk Transfer mBanking",
            "mbanking_trf_guide_1": "Login ke mBanking-mu, Pilih Transaksi, Kemudian Cari BCA Virtual Account",
            "mbanking_trf_guide_2": "Masukkan nomor Virtual Account",
            "mbanking_trf_guide_3": "Pastikan Nama Dan Nominal Bayar Benar",
            "me": "Saya",
            "member_only_class": "Kelas Khusus Member",
            "mentri_perdagangan": "Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga, Kementerian Perdagangan",
            "menu_list": "Daftar Menu",
            "message": "Pesan",
            "minimum_input_character": "Masukan Minimal {{length}} Karakter",
            "minimum_passing_standards": "Standar Kelulusan Minimum",
            "minutes": "Menit",
            "moq": "Jumlah Pemesanan Minimum",
            "msds": "Lembar Data Keselamatan Bahan (Material Safety Data Sheet)",
            "name": "Nama",
            "new": "Baru",
            "new_address": "Alamat Baru",
            "news": "News",
            "no_product_yet": "Tidak Memiliki Produk",
            "note_to_admin": "Catatan Ke Admin",
            "notice": "Perhatian",
            "npwp_image": "Foto NPWP",
            "npwp_number": "Nomor NPWP",
            "old": "Lama",
            "old_order_history_list": "Old Order History List",
            "old_page_redirect": "Ini Adalah Halaman Terbaru, Anda Bisa Akses Link Berikut: {{link}} Untuk Melihat Halaman Yang Lama",
            "old_shipping": "Shipping Lama",
            "onboarding_quiz": "Onboarding Quiz",
            "optional": "Opsional",
            "order_com_status": {
                "CUSTOMER PAID": "KONSUMEN SUDAH MEMBAYAR",
                "SUPPLIER PAID": "PENYEDIA SUDAH DIBAYAR",
                "IN WAREHOUSE": "SUDAH DI GUDANG",
                "IN SHIPPING": "DALAM PENGIRIMAN",
                "IN WAREHOUSE COUNTRY": "SUDAH DIGUDANG NEGARA TUJUAN",
                "DONE": "SELESAI"
            },
            "order_details": "Detail Pesanan",
            "order_history_list": "Order History List",
            "order_invoice_attention_msg": "Terima kasih atas perhatian Anda. Daftar Yang Ditampilkan Diatas Mencerminkan Jumlah Keseluruhan Dari Semua Pesanan Yang Anda Lakukan. Kami Menghargai Transaksi Lanjutan Anda Dengan Kami.",
            "order_list": "Order List",
            "order_now": "Pesan Sekarang",
            "order_number": "Nomor Pemesanan",
            "order_unavailable": "Pesanan Tidak Tersedia",
            "other": "Lainnya",
            "other_courses": "Kursus Lainnya",
            "other_file": "Berkas Lainnya",
            "packages": "Paket",
            "packing_list": "Daftar Pengepakan",
            "packing_list_file": "Berkas Daftar Pengepakan",
            "paid": "Terbayar",
            "paid_at": "Tanggal Pembayaran",
            "password": "Kata Sandi",
            "pay": "Bayar",
            "pay_with_bca": "Bayar Melalui BCA",
            "pay_with_cc": "Bayar Melalui Kartu Kredit",
            "pay_with_other": "Bayar Melalui Bank Lain",
            "payment_confirmation": "Konfirmasi Pembayaran",
            "payment_information_for": "Informasi Pembayaran Untuk",
            "payment_method": "Metode Pembayaran",
            "payment_status": "Status Pembayaran",
            "payment_verif_va_info_1": "Proses Verifikasi Otomatis Kurang Dari 10 Menit Setelah Pembayaran Berhasil",
            "payment_verif_va_info_2": "Bayar Ke Virtual Account Di Atas Sebelum Membuat Donasi Baru Dengan Virtual Account Agar Nomor Tetap Sama.",
            "personalized_catalog": "Personalized Catalog",
            "phone_number": "Nomor Telepon",
            "please_choose_address": "Silahkan pilih alamat Anda berdasarkan kota atau kabupaten atau kecamatan",
            "please_type": "Mohon Ketik",
            "postal_code": "Kode Pos",
            "pph": "PPh",
            "ppn": "PPn",
            "ppn_handling_fee": "PPn Biaya Penanganan",
            "price": "Harga",
            "print_invoice": "Cetak Invoice",
            "print_label": "Cetak Label",
            "proceed_to_cart": "Lanjutkan Ke Keranjang",
            "product": "Produk",
            "product_category_info": "* Anda berada di kategori produk {{country}}",
            "product_cost_info": "Produk & Informasi Biaya",
            "product_description": "Deskripsi Produk",
            "product_id": "ID Produk",
            "product_image": "Gambar Produk",
            "product_info": "Informasi Produk",
            "product_link": "Tautan (Link) Produk",
            "product_list": "Daftar Produk",
            "product_name": "Nama Produk",
            "product_price": "Harga Produk",
            "product_price_total_w_rate": "Total Harga Produk ({{currency}})",
            "product_type": "Tipe Produk",
            "product_unavailable": "Produk Tidak Tersedia",
            "profile": "Profile",
            "promo": "Kode Promo",
            "promo_code": "Kode Promo",
            "promo_code_info": "Kode Promo Tidak Bisa Digunakan Bersamaan Dengan Saldo CBM",
            "quantity": "Kuantitas",
            "random": "Acak",
            "random_product_info": " Atas Kualitas Produk Seperti Kondisi, Varian, Jenis, Warna, Model, Dan Pembelian Produk {{random}}",
            "rate": "Kurs",
            "rate_to": "Kurs {{from}} Ke {{to}}",
            "ready_from": "Siap Dikirim Dari {{country}}",
            "real": "NYATA",
            "receiver_name": "Nama Penerima",
            "receiver_phone": "Nomor Telepon Penerima",
            "refund": "Refund",
            "refund_agreement": "Perjanjian Refund",
            "refund_agreement_title": "Anda Telah Mengajukan Permintaan Refund Sebagai Berikut:",
            "refund_balance": "Saldo Pengembalian (Refund)",
            "refund_info": "Transaksi Akan Dimulai Setelah Tim Finance Menyetujui Refund Ini, Diperkirakan Dalam Waktu 3-5 Hari Kerja Sejak Konfirmasi Transaksi Ini. Jika Anda Memiliki Pertanyaan, Silakan Hubungi Layanan Pelanggan Kami Melalui Obrolan Di Sudut Kanan Bawah Halaman Web Ini.",
            "refund_info_bold": "3-5 Hari Kerja",
            "refund_policy": "Refund Policy",
            "refund_policy_page": [
                "Cara Mengajukan Komplain",
                "Sobat Portir Wajib Membuat Video Unboxing Tanpa Jeda Mulai Dari Kemasan Belum Dibuka Sampai Semua Produk Selesai Dikeluarkan Dari Kemasan.",
                "2. Informasikan Kendala Ke Whatsapp Official IMPORTIR.COM {{link}} Dan Tim Support Akan Memberikan Link Google Form.",
                "Input Data Complain Ke Link Google Form Yang Dikirimkan Tim Support.",
                "Syarat Dan Ketentuan Pengajuan Komplain",
                "Komplain Di Sampaikan Ke Tim Support IMPORTIR.COM Maksimal 3 X 24 Jam Sejak Barang Di Terima.",
                "Sobat Portir Wajib Membuat Video Unboxing Sebagai Bukti Bahwa Dari Kondisi Barang Yang Di Terima Tidak Sesuai Dengan Orderan. Video Yang Di Kirim Tidak Boleh Terpotong/terjeda Atau Gabungan Beberapa Video Melainkan Satu Video Secara Utuh.",
                "Video Unboxing Harus Memuat Proses Mulai Dari Memperlihatkan Nomor Order Dan Resi Yang Tertempel Pada Kemasan Luar Produk Saat Kondisi Kemasan Paket Masih Terbungkus, Penghitungan Jumlah Produk, Pengecekan Kondisi Barang Yang Diterima, Sampai Semua Produk Selesai Dikeluarkan.",
                "Jika Complain Yang Ingin Di Sampaikan Ke Supplier Adalah Kerusakan Barang, Maka Wajib Melampirkan Foto Detail Bagian Kerusakan Produk.",
                "Proses Pengembalian Dana Akan Diterima Estimasi 3 - 5 Hari Kerja Sejak Sobat Portir Melakukan Konfirmasi Refund Melalui Email Atau Melalui Menu Refund Di Website IMPORTIR.COM. Proses Bisa Lebih Lama Jika Terdapat Kendala Tertentu.",
                "IMPORTIR.COM Tidak Dapat Menerima Complain Pengembalian Dana Dengan Alasan Produk Tidak Berfungsi. Karena Kualitas Produk Adalah Tanggung Jawab Supplier. Namun IMPORTIR.COM Akan Membantu Melakukan Komplain Dan Klaim Ke Supplier.",
                "IMPORTIR.COM Tidak Bertanggung Jawab Jika Kerusakan Produk Terjadi Karena Pengiriman Ekpedisi Lokal Indonesia. Namun IMPORTIR.COM Akan Membantu Proses Komplain Dan Klaim Ke Ekpedisi Terkait.",
                "Apabila Pengembalian Dana Dikarenakan Jumlah Produk Yang Di Terima Tidak Sesuai Pesanan Atau Kurang Saat Di Terima Di Alamat Tujuan, Tim Importir.com Akan Membantu Melakukan Komplain Dan Konfirmasi Ke Supplier Karena Sudah Menjadi Tugas Kami Sebagai Penghubung Antara Sobat Portir Dengan Supplier. Setelah Supplier Melakukan Pengembalian Dana, Maka Pengembalian Dana Ke Sobat Portir Akan Segera Dilakukan.",
                "Apabila Pengembalian Dana Dikarenakan Barang Tertahan Atau Hilang Di Beacukai/custom, Maka Akan Menjadi Tanggung Jawab Importir.com Sepenuhnya.",
                "Komplain Tidak Dapat Diterima Jika",
                "Tidak Mengirimkan Dan Membuat Bukti Video Unboxing Dan Foto Barang Yang Dikomplain.",
                "Melakukan Komplain Setelah 3 Hari Sejak Barang Diterima Di Alamat Tujuan.",
                "Video Unboxing Terpotong Atau Terjeda Atau Merupakan Gabungan Dari Beberapa Video.",
                "Video Tidak Memperlihatkan Nomor Order Dan Resi Yang Tertempel Pada Bagian Luar Kemasan Paket.",
                "Kerusakan Terjadi Saat Proses Pengiriman Yang Di Sebabkan Oleh Ekspedisi.",
                "Komplain Dapat Diterima Jika",
                "Sobat Portir Mengirimkan Dan Membuat Bukti Video Unboxing Dan Foto Barang Yang Dikomplain.",
                "Melakukan Komplain Sebelum 3 Hari Sejak Barang Di Terima Di Alamat Tujuan.",
                "Video Yang Dibuat Merupakan Satu Video Utuh Yang Tidak Terjeda Atau Terpotong Dan Bukan Gabungan Beberapa Video.",
                "Video Memperlihatkan Nomor Order Dan Resi Yang Tertempel Pada Bagian Luar Kemasan Paket.",
                "Terdapat Kerusakan, Kesalahan, Atau Kekurangan Pada Pesanan Saat Orderan Diterima Di Gudang IMPORTIR.COM Dan Merupakan Kesalahan Dari Tim IMPORTIR.COM.",
                "Force Majeure",
                "IMPORTIR.COM Tidak Bertanggung Jawab Jika Terdapat Kendala Atau Hambatan Yang Diakibatkan Keadaan Tertentu Yang Berada Di Luar Kendali Tim IMPORTIR.COM, Yang Merupakan Peristiwa Tidak Terduga, Diantaranya",
                "Situasi Lockdown Karena Pandemi, Seperti Yang Terjadi Saat Peristiwa Covid19 Di China",
                "Kebakaran, Ledakan, Badai, Banjir, Gempa Bumi, Longsor, Wabah Dan Bencana Alam Lainnya",
                "Kendala Sehingga Tidak Dapat Digunakannya Kereta Api, Pesawat Pengiriman, Maupun Alat Transportasi Lainnya Yang Dapat Menganggu Proses Pendistribusian Barang Atau Produk",
                "Tidak Dapat Digunakannya Jaringan Internet Dan Telekomunikasi",
                "Tindakan, Keputusan, Undang-undang, Peraturan Atau Pembatasan Dari Pemerintah, Seperti Aksi Mogok Transportasi Yang Berkaitan Dengan Proses Importasi",
                "Keributan, Kerusuhan, Invasi, Serangan/ancaman Teroris, Serangan/Ancaman/Persiapan Untuk Perang",
                "Jika Force Majeure Terjadi Selama Lebih Dari Satu Minggu, Sobat Portir Atau Importir.com Dapat Membatalkan Pesanan Melalui Pemberitahuan Tertulis Dan Melakukan Pengembalian Uang Yang Sudah Dibayarkan Namun Belum Dilakukan Proses Penerusan Dana Ke Supplier"
            ],
            "refund_statuses": {
                "APPROVE ADMIN": "DISETUJUI ADMIN",
                "APPROVE SHIPPING": "DISETUJUI TIM SHIPPING",
                "APPROVE CUSTOMER": "DISETUJUI CUSTOMER",
                "APPROVE FINANCE": "DISETUJUI TIM FINANCE",
                "PUSH PLATFORM": "DITERUSKAN KE PLATFORM",
                "TRANSFERRED SUCCESS": "TRANSFER SUKSES",
                "REJECT SHIPPING": "DITOLAK TIM SHIPPING",
                "REJECT FINANCE": "DITOLAK TIM FINANCE"
            },
            "refund_transaction_list": "Daftar Refund",
            "remaining_cbm_balance": "Sisa Saldo CBM Anda",
            "remittence": "Remittensi",
            "required": "Wajib",
            "reset": "Setel Ulang",
            "save": "Simpan",
            "sea": "LAUT",
            "search": "Cari",
            "search_by_image": "Cari Berdasarkan Gambar",
            "search_pl_news_page": "Cari Berdasarkan Judul atau Deskripsi",
            "searching_product": "Sedang Mencari Produk",
            "second_bill_estimation": "Estimasi Tagihan Kedua",
            "seconds": "Detik",
            "see_all": "Lihat Semua",
            "select_address_by_district_or_city": "Pilih Alamat Berdasarkan Kabupaten atau Kecamatan Yang Dimasukan",
            "select_freight": "Pilih Pengiriman",
            "select_placeholder": "-- Silahkan Pilih --",
            "selected_product": "Produk Pilihan",
            "sent_to": "Dikirim Ke",
            "service": "Layanan",
            "sfy_id": "ID Source for You",
            "sfy_info": "Source For You Adalah Fitur Yang Akan Membantu Member IMPORTIR ORG / IMPORTIR COM Untuk Mendapatkan Produk Yang Diinginkan Dan Menentukan Harga Pokok Penjualan Dengan Cara Semudah Mungkin.",
            "sfy_unavailable": "Daftar Source For You Tidak Tersedia",
            "shipping_address": "Alamat Pengiriman",
            "shipping_by": "Pengiriman Dengan",
            "shipping_detail": "Detail Shipping",
            "shipping_fee": "Biaya Pengiriman",
            "shipping_fee_to": "Biaya Pengiriman Ke {{country}}",
            "shipping_file": "Berkas Shipping",
            "shipping_method": "Metode Pengiriman",
            "shipping_number": "Nomor Shipping",
            "shipping_status": {
                "CUSTOMER READY": "KONSUMEN SUDAH SIAP",
                "ADMIN CONFIRM": "TERKONFIRMASI OLEH ADMIN",
                "CUSTOMER PAID": "KONSUMEN SUDAH MEMBAYAR",
                "SUPPLIER PAID": "PENYEDIA SUDAH DIBAYAR",
                "IN WAREHOUSE": "SUDAH DIGUDANG",
                "IN SHIPPING": "DALAM PENGIRIMAN",
                "IN WAREHOUSE COUNTRY": "SUDAH DIGUDANG NEGARA TUJUAN",
                "DONE": "SELESAI"
            },
            "shop_again": "Belanja Produk Lainnya",
            "shop_empty": "Whoa, keranjang belanja anda kosong",
            "shop_from": "Dibeli Dari",
            "show_detail": "Tampilkan Detail",
            "sign_out": "Sign Out",
            "simulator": "Simulasi",
            "sold": "Terjual",
            "start": "Mulai",
            "start_learning_today": "Mulai Belajar Hari Ini",
            "start_on": "Mulai Pada",
            "start_tour": "Mulai Tour",
            "stock": "Persediaan",
            "submit": "Kirim",
            "success_add_to_cart": "Anda Berhasil Menambahkan Produk Ke Keranjang Anda.",
            "summary": "Ringkasan",
            "supplier": "Penyedia",
            "supplier_bank_info": "Informasi Bank Penyedia",
            "supplier_data": "Data Penyedia",
            "supplier_email": "Email Penyedia",
            "supplier_file": "Berkas Penyedia",
            "supplier_name": "Nama Penyedia",
            "supplier_paid_file": "Berkas Pembayaran Penyedia",
            "supplier_phone": "Nomor Telepon Penyedia",
            "supplier_wechat": "ID Wechat Penyedia",
            "tax_diff_info": "Perbedaan Pajak Ditentukan Berdasarkan Basis Data Pemerintah (HSCode)",
            "term_condition": "Syarat & Ketentuan",
            "thank_you": "Terima Kasih",
            "title": "Judul",
            "total": "Jumlah",
            "total_price_including_tax": "Total Harga Sudah Termasuk Pajak dan Lainnya.",
            "total_product_price": "Jumlah Harga Produk",
            "tour_home_banner": "Berikut Adalah Bagian Banner Untuk Memberi Tahu Anda Apa Saja Yang Tersedia",
            "tour_home_cart": "Di Bagian Ini Anda Dapat Melihat Daftar Barang Yang Telah Anda Masukkan Ke Dalam Keranjang Anda.",
            "tour_home_category": "Bagian Kategori Ini Untuk Memudahkan Anda Menemukan Produk Yang Anda Inginkan",
            "tour_home_crowdfund": "Ini Adalah Bagian Crowdfund, Di Bagian Ini Anda Akan Mendapatkan Daftar Produk Crowdfund Terbaru",
            "tour_home_menu": "Anda Dapat Mengakses Untuk Melihat Menu Apa Saja yang Tersedia",
            "tour_home_news": "Ini Adalah Bagian News, Di Bagian Ini Anda Akan Mendapatkan Berita Tentang Barang Apa Saja Yang Sedang Tren",
            "tour_home_profile": "Disini Anda Bisa Melihat Menu Profile, Order, Invoice, Contact Us Dan Log Out",
            "tour_home_quick_menu": "Ini Adalah Bagian Menu Cepat Untuk Mempermudah Anda Mengakses Menu",
            "tour_home_search_by_image": "Anda Dapat Mencari Produk Berdasarkan Gambar",
            "tour_home_user_info": "Nama Pengguna Dan Informasi Paket Pengguna Saat Masuk",
            "tour_home_wishlist": "Ini Adalah Bagian Wishlist, Di Bagian Ini Anda Bisa Melihat Wishlist Yang Sudah Anda Simpan",
            "tour_shipping_form_additional_fee": "Pada Bagian Ini Anda Dapat Menambahkan Biaya Tambahan Jika Ada",
            "tour_shipping_form_address": "Pada Bagian Ini Anda Dapat Memilih Alamat Yang Akan Anda Gunakan Untuk Pengiriman",
            "tour_shipping_form_cbm_balance": "Pada Bagian Ini Anda Dapat Menggunakan Saldo Cbm Yang Anda Miliki.",
            "tour_shipping_form_country": "Pada Bagian Ini Anda Dapat Memilih Apakah Anda Ingin Membeli Barang Dari China Atau Thailand Dan Yang Nantinya Akan Menyesuaikan Tarif Harga.",
            "tour_shipping_form_freight": "Pada Bagian Ini Anda Diharuskan Mengisi Jenis Pengiriman, Pengiriman, Estimasi Volume, Estimasi Berat, Dan Anda Juga Bisa Menambahkan Packing Kayu.",
            "tour_shipping_form_invoice": "Pada Bagian Ini Anda Dapat Memasukkan Informasi Tentang Supplier Barang Yang Akan Anda Beli.",
            "tour_shipping_form_others": "Di Bagian Ini Anda Dapat Memilih Pengiriman Lokal Dan Anda Juga Dapat Meninggalkan Catatan Untuk Admin.",
            "tour_shipping_form_product_cost": "Pada Bagian Ini Anda Dapat Memasukkan Produk Yang Ingin Anda Beli.",
            "tour_shipping_form_promo": "Pada Bagian Ini Anda Dapat Memasukkan Kode Promo Yang Anda Miliki",
            "tour_shipping_form_summary": "Pada Bagian Ini Anda Dapat Melihat Rincian Estimasi Harga Barang Yang Akan Anda Beli.",
            "tour_shipping_index_create_shipping": "Tombol Ini Digunakan Untuk Membuat Pesanan Shipping Baru",
            "tour_shipping_index_detail": "Di Bagian Ini Anda Dapat Melihat Detail Pesanan Shipping Anda.",
            "tour_shipping_index_old_order": "Di Bagian Ini Anda Dapat Melihat Riwayat Pesanan Lama Anda.",
            "tour_shipping_index_simulator": "Tombol Ini Digunakan Untuk Menuju Halaman Simulator Shipping",
            "transaction_type": "Tipe Transaksi",
            "transparent": "Transparan",
            "type": "Tipe",
            "typing_dont_match": "Pengetikan Anda Tidak Cocok, Harap Ketik Ulang",
            "unpaid": "Belum Terbayar",
            "updated_at": "Tanggal Diperbaharui",
            "upload_supplier_info": "Unggah Data Penyedia Untuk Proses Pembayaran Lebih Cepat",
            "use": "Gunakan",
            "use_cbm_balance": "Penggunaan Saldo CBM",
            "use_cbm_balance_from": "Gunakan {{balance}} CBM Dari {{type}}",
            "use_voucher": "Gunakan Voucher",
            "used_cbm_total": "Jumlah Saldo CBM Yang Digunakan",
            "user_information": "Informasi Pengguna",
            "va_number": "Nomor Virtual Account",
            "variant": "Variasi",
            "video_not_found": "Video Tidak Ditemukan",
            "view": "Lihat",
            "view_profile": "Lihat Profile",
            "voucher_code": "Kode Voucher",
            "voucher_list": "Daftar Voucher",
            "voucher_order": "Voucher Order",
            "voucher_questions": "Pertanyaan Voucher",
            "waiting_for_payment": "Menunggu Pembayaran",
            "want_to_add_address": "Ingin Menambahkan Alamat Baru?",
            "warehouse_delivery_fee": "Biaya Pengiriman Gudang",
            "we_always_transparent": "Kami Selalu {{transparent}}",
            "weight": "Berat",
            "welcome_to_importir": "Selamat Datang Di Importir",
            "will_end_on": "Akan Berakhir Pada",
            "wishlist": "Daftar Keinginan",
            "wooden_packing": "Packing Kayu",
            "wooden_packing_agreement": "Jika Anda Mengklik YA, Anda Setuju Untuk Biaya Tambahan Untuk Bingkai Kayu 130RMB / kotak Dan Ketika Anda Mengklik YA Tidak Dapat Dibatalkan. Jika Anda Setuju, Silakan Lanjutkan Dengan Mengklik",
            "wooden_packing_policy": "Kami Tidak Bertanggung Jawab Atas Kerusakan Apapun Tanpa Kemasan Kayu."
        }
    },
    "en": {
        "translation": {
            "academy": "Academy",
            "account_number": "Account Number",
            "actions": "Actions",
            "actual_cbm": "Actual CBM",
            "actual_weight": "Actual Weight",
            "add": "Add",
            "add_file": "Add File",
            "add_new_address": "Add New Address",
            "add_to_cart": "Add To Cart",
            "additional_fee": "Additional Fee",
            "additional_file": "Additional File",
            "additional_request_fragile_info": "There Is A Request For Additional Packing, Especially For Fragile Items",
            "additional_shipping_fee_info": "There Is An Additional Shipping Fee From The Seller To Our Warehouse Overseas",
            "address": "Address",
            "address_label": "Address Label",
            "admin_file": "Admin File",
            "admin_note": "Admin Note",
            "advance_class": "Advance Class",
            "air": "AIR",
            "all": "All",
            "all_product": "All Product",
            "all_right_reserved": "All Rights Reserved",
            "already_have_product": "Already Have A Product",
            "already_know": "Already Know What Is",
            "anteraja_info": "For Anteraja Options Only At More Than 1 Kg And Less Than 50 Kg. Make Sure Your Postal Code On Destination Address Is Exist.",
            "apply_cbm_balance": "Apply CBM Balance",
            "apply_promo": "Apply Promo Code",
            "approve": "Approve",
            "approved_at": "Approved At",
            "back": "Back",
            "balance_type": "Balance Type",
            "bank_code": "Bank Code",
            "battery": "Battery",
            "beginner_class": "Beginner Class",
            "bill_detail": "Bill Detail",
            "bill_title": "Bill Title",
            "birth": "Birth",
            "bm": "Import Duty",
            "buy_again": "Buy Again",
            "buy_now": "Buy Now",
            "buy_package": "Buy Package",
            "buyer": "Buyer",
            "calculate": "Calculate",
            "calculate_result": "Calculate Result",
            "calculator": "Calculator",
            "cancel": "Cancel",
            "cancel_cbm_balance": "Cancel CBM Balance",
            "cancel_payment": "Cancel Payment",
            "cancel_promo": "Cancel Promo Code",
            "cancel_voucher": "Cancel Voucher",
            "cart": "Cart",
            "catalog": "Catalog",
            "category": "Category",
            "cbm_amount": "CBM Amount",
            "cbm_balance": "CBM Balance",
            "cbm_balance_form": "CBM Balance Form",
            "cbm_package": "CBM Package",
            "change": "Change",
            "change_address": "Change Address",
            "change_password": "Change Password",
            "chat_to_admin": "Chat To Admin",
            "checkout": "Checkout",
            "choose_address": "Choose Address",
            "choose_bank": "Choose Bank",
            "choose_district_or_city_placeholder": "-- Choose the district or city --",
            "choose_mode": "Choose Mode",
            "choose_voucher": "Choose Voucher",
            "city": "City",
            "click": "Click",
            "click_for_detail": "Click For More Detail Information",
            "click_here_now": "Click Here Now",
            "click_to_use_promo_code": "Click The Button If You Want To Apply Promo Code",
            "close": "Close",
            "coming_soon": "Coming Soon",
            "commercial_invoice_file": "Commercial Invoice File",
            "company": "Company",
            "complaint_deadline": "Should There Be Any Complaint Regarding Your Products, Please Claim Within 3 Working Days After Receiving. Submit Your Complaint Along With An Unboxing Video And Pictures Via IMPORTIR.COM Official WhatsApp Number +6287800174596",
            "confirm": "Confirm",
            "confirmation_message": "Confirmation Message",
            "confused_about_cogs": "Confused About Product Types And COGS Calculations? Our Admin Will Find It For You!",
            "consultation": "Consultation",
            "contact_us": "Contact Us",
            "copy": "Copy",
            "cost_info": "Cost Information",
            "country": "Country",
            "create_order": "Create Order",
            "create_shipping": "Create Shipping",
            "created_at": "Created At",
            "crowdfund": "Crowdfund",
            "crowdfund_feature": "Crowdfund Feature",
            "crowdfund_feature_exp": "The Crowdfund Feature Provides A Variety Of Product Options That You Can Use As References Or List For Your Sales Products Without Having To Worry About Hs Code, Shipping Fees, And Taxes. The Product Prices Within Crowdfund Are The Final Price (COGS) Until They Reach The Warehouse In Indonesia (excluding Shipping Costs To Your Address).",
            "crowdfund_feature_list_1": "From Indonesia: Products That Are Already In Stock In Indonesia And Ready To Be Shipped Throughout Indonesia.",
            "crowdfund_feature_list_2": "From China: Products That Need To Be Imported From China Will Take 30-40 Days To Reach Your Address.",
            "crowdfund_feature_list_title": "There Are (2) Two Types Of Crowdfund Feature:",
            "crowdfund_has_ended": "Product Crowdfund Has Ended",
            "customer_address": "Customer Address",
            "customer_complaint_service": "Customer Complaint Service",
            "data_not_found": "Data Not Found",
            "date": "Date",
            "days": "Days",
            "delete": "Delete",
            "delivery_fee_local": "Delivery Fee Local",
            "demo": "DEMO",
            "demo_mode_info": "Demo Mode Is Only Used For Practice. The Transaction Won't Be Checked By Admin",
            "description": "Description",
            "diff_alert": "You have the difference type of product (lartas / non lartas), please make a type in cart is same.",
            "digital_invoice_policy": "Invoice Was Created On A Computer And Is Valid Without The Signature And Seal.",
            "discount": "Discount",
            "district": "District",
            "does_item_contain": "Does the item contain?",
            "dont_have_history_wishlist": "You Don't Have Any History Product Wishlist",
            "edit": "Edit",
            "edit_profile": "Edit Profile",
            "escrow_statuses": {
                "Admin Confirm": "Admin Confirm",
                "Cancel": "Cancel",
                "Customer Paid": "Customer Paid",
                "Done": "Done",
                "customer ready": "Customer Ready"
            },
            "estimate": "Estimate",
            "estimated_cbm": "Estimated CBM",
            "estimated_info": "The Weight Or Volume You Input Does Not Match The Real Weight / Volume Of Your Items When It Arrives At The Warehouse",
            "estimated_price": "Estimated Price",
            "estimated_total_purchase": "Estimated Total Purchase",
            "estimated_weight": "Estimated Weight",
            "exclusive_for_member": "Exclusive For Member",
            "expired_order_invoice": "This Order Has Expired, Please Contact Admin For More Information",
            "file": "File",
            "file_and_documents": "File & Documents",
            "fill_in_my_purchase_request": "Fill In My Purchase Request",
            "find_dream_product": "Fill In The Form Below To Find Your Dream Item",
            "find_your_class": "Find A Class That Suits Your Needs",
            "first_bill_estimation": "First Bill Estimation",
            "form": "Form",
            "fragile": "Fragile",
            "freight": "Freight",
            "freight_desc": "Freight Description",
            "from": "From",
            "full_address": "Full Address",
            "get_additional_fee_info": "In The Process You May Get Additional Price Information Notifications If",
            "grand_total": "Grand Total",
            "handling_fee": "Handling Fee",
            "history": "History",
            "home": "Home",
            "hours": "Hours",
            "hpp": "COGS",
            "i_agree_with_info": "I Agree With The Detail Information",
            "id_image": "ID Card Image",
            "id_number": "ID Card Number",
            "import_from": "Import From",
            "importir_news": "Importir News",
            "in_warehouse_indo_policy": "If The Goods Have Arrived At Indonesia's Warehouse And Within 30 (thirty) Calendar Days The First Party Does Not Pay All The Costs Stated On The Invoice, Then The Goods Ordered By The First Party Are Considered Forfeited. Accordingly, The First Party Authorizes The Second Party Over These Items.",
            "information_detail": "Information Detail",
            "input_balance_cbm": "Input Balance CBM",
            "input_cbm": "Input CBM",
            "input_weight": "Input Weight",
            "invoice": "Invoice",
            "invoice_file": "Invoice File",
            "invoice_history_list": "Invoice History List",
            "invoice_list": "Invoice List",
            "invoice_number": "Invoice Number",
            "invoice_packing_list": "Invoice & Packing list",
            "invoice_unavailable": "Invoice Unavailable",
            "item": "Item",
            "item_may_opened_info": "And I Agree If My Item Is Opened Due To A Random Check By The Customs & Excise Officer",
            "additional_quality": "IMPORTIR.COM is not responsible for the product quality, such as condition, variants, type, color, model, and random/occasional product purchases.",
            "additional_free_shipping": "Free shipping applies to local Indonesia JABODETABEK deliveries for SHP order codes. Exclusions include Helper Fees, Additional Packing Fees, Toll Fees, Courier Waiting Fees, and Heavy Equipment Usage Fees (if applicable). Not valid for orders with Promo Codes and CBM Balance.",
            "job": "Job",
            "last_status": "Last Status",
            "latest_news": "Latest News",
            "learn_now": "Learn Now",
            "lets_shop_now": "Let's Shop Now",
            "liquid": "Liquid",
            "list_address": "List Address",
            "list_sfy": "List Source For You",
            "load_more": "Load More",
            "login_to_account": "Log In To Your Account",
            "logistic": "Logistic",
            "main_account_number": "Main Account Number",
            "max_upload": "The Max Size For Upload Is 2MB",
            "maximum_input_cbm_balance": "The Maximum Input Value Is Set To {{cbm}} Cbm.",
            "mbanking_trf_guide": "mBanking Transfer Guide",
            "mbanking_trf_guide_1": "Login to your mBanking, Choose Transaction, then Search BCA Virtual Account",
            "mbanking_trf_guide_2": "Enter Virtual Account number",
            "mbanking_trf_guide_3": "Make Sure The Name And Payment Amount Are Correct",
            "me": "Me",
            "member_only_class": "Member Only Class",
            "mentri_perdagangan": "Directorate General Of Consumer Protection And Orderly Commerce, Ministry Of Trade",
            "menu_list": "Menu List",
            "message": "Message",
            "minimum_input_character": "Minimum Input Of {{length}} Characters",
            "minimum_passing_standards": "Minimum Passing Standards",
            "minutes": "Minutes",
            "moq": "Minimum Order Quantity",
            "msds": "MSDS (Material Safety Data Sheet)",
            "name": "Name",
            "new": "New",
            "new_address": "New Address",
            "news": "News",
            "no_product_yet": "Doesn't Have Product",
            "note_to_admin": "Note to Admin",
            "notice": "Notice",
            "npwp_image": "Tax Number Image",
            "npwp_number": "Tax Number",
            "old": "Old",
            "old_order_history_list": "Old Order History List",
            "old_page_redirect": "This Is The New Page, You Can {{link}} To See The Old Page",
            "old_shipping": "Old Shipping",
            "onboarding_quiz": "Onboarding Quiz",
            "optional": "Optional",
            "order_com_status": {
                "CUSTOMER PAID": "CUSTOMER PAID",
                "SUPPLIER PAID": "SUPPLIER PAID",
                "IN WAREHOUSE": "IN WAREHOUSE",
                "IN SHIPPING": "IN SHIPPING",
                "IN WAREHOUSE COUNTRY": "IN WAREHOUSE COUNTRY",
                "DONE": "DONE"
            },
            "order_details": "Order Details",
            "order_history_list": "Order History List",
            "order_invoice_attention_msg": "Thank You For Your Attention. The List Displayed Above Reflects The Comprehensive Sum Of All Your Placed Orders. We Appreciate Your Continued Transactions With Us.",
            "order_list": "Order List",
            "order_now": "Order Now",
            "order_number": "Order Number",
            "order_unavailable": "Order Unavailable",
            "other": "Other",
            "other_courses": "Other Courses",
            "other_file": "Other File",
            "packages": "packages",
            "packing_list": "Packing List",
            "packing_list_file": "Packing List File",
            "paid": "Paid",
            "paid_at": "Paid At",
            "password": "Password",
            "pay": "Pay",
            "pay_with_bca": "Pay With BCA",
            "pay_with_cc": "Pay With Credit Card",
            "pay_with_other": "Pay With Other Banks",
            "payment_confirmation": "Payment Confirmation",
            "payment_information_for": "Payment Information For",
            "payment_method": "Payment Method",
            "payment_status": "Status Pembayaran",
            "payment_verif_va_info_1": "Automatic Verification Process Less than 10 Minutes After Successful Payment",
            "payment_verif_va_info_2": "Pay To The Above Virtual Account Before Making A New Payment With A Virtual Account To Keep The Same Number.",
            "personalized_catalog": "Personalized Catalog",
            "phone_number": "Phone Number",
            "please_choose_address": "Please choose your address based on the city or the district",
            "please_type": "Please Type",
            "postal_code": "Postal Code",
            "pph": "Income Tax",
            "ppn": "VAT",
            "ppn_handling_fee": "VAT Handling Fee",
            "price": "Price",
            "print_invoice": "Print Invoice",
            "print_label": "Print Label",
            "proceed_to_cart": "Proceed to Cart",
            "product": "Product",
            "product_category_info": "* You are in the {{country}} products category",
            "product_cost_info": "Product & Cost Information",
            "product_description": "Product Description",
            "product_id": "Product ID",
            "product_image": "Product Image",
            "product_info": "Product Information",
            "product_link": "Product Link",
            "product_list": "Product List",
            "product_name": "Product Name",
            "product_price": "Product Price",
            "product_price_total_w_rate": "Product Price Total ({{currency}})",
            "product_type": "Product Type",
            "product_unavailable": "Product Unavailable",
            "profile": "Profile",
            "promo": "Promo Code",
            "promo_code": "Promo Code",
            "promo_code_info": "Promo Code Can't Be Used With CBM Balance",
            "quantity": "Quantity",
            "random": "Random",
            "random_product_info": "IMPORTIR.COM Is Not Responsible For The Condition, Variant, Type, Color, Model, And Product Received For {{random}} Product Types",
            "rate": "Rate",
            "rate_to": "Rate {{from}} To {{to}}",
            "ready_from": "Ready From {{country}}",
            "real": "REAL",
            "receiver_name": "Receiver Name",
            "receiver_phone": "Receiver Phone",
            "refund": "Refund",
            "refund_agreement": "Refund Agreement",
            "refund_agreement_title": "You Have Submitted A Refund Request As Follows :",
            "refund_balance": "Refund Balance",
            "refund_info": "The Transaction Will Commence Once Finance Approves This Refund, Estimated Within 3-5 Days From The Confirmation Of This Transaction. If You Have Any Questions, Please Contact Our Customer Service Via The Chat In The Bottom Right Corner Of This Web Page.",
            "refund_policy": "Refund Policy",
            "refund_policy_page": [
                "How to File a Complaint",
                "Porters Are Required To Make An Unboxing Video Without Pause From The Unopened Packaging Until All Products Have Been Removed From The Packaging.",
                "2. Inform The Obstacle To Whatsapp Official IMPORTIR.COM {{link}} And The Support Team Will Provide A Google Form Link.",
                "Input Complain Data To The Google Form Link Sent By The Support Team.",
                "Terms and Conditions for Filing a Complaint",
                "Complaints Are Submitted To The IMPORTIR.COM Support Team A Maximum Of 3 X 24 Hours Since The Goods Are Received.",
                "Porters Are Required To Make An Unboxing Video As Proof That The Condition Of The Goods Received Does Not Match The Order. Videos That Are Sent Must Not Be Cut / Paused Or A Combination Of Several Videos But One Whole Video.",
                "The Unboxing Video Must Contain The Process Starting From Showing The Order Number And Receipt Attached To The Outer Packaging Of The Product While The Package Is Still Wrapped, Counting The Number Of Products, Checking The Condition Of The Goods Received, Until All Products Are Released.",
                "If The Complaint You Want To Convey To The Supplier Is Damage To The Goods, Then You Must Attach A Detailed Photo Of The Damaged Part Of The Product.",
                "The Refund Process Will Be Received In An Estimated 3 - 5 Working Days Since You Confirm The Refund Via Email Or Through The Refund Menu On The IMPORTIR.COM Website. The Process Can Take Longer If There Are Certain Obstacles.",
                "IMPORTIR.COM Cannot Accept Refund Complaints On The Grounds That The Product Does Not Work. Because The Quality Of The Product Is The Responsibility Of The Supplier. However, Importir.com Will Assist In Making Complaints And Claims To Suppliers.",
                "IMPORTIR.COM Is Not Responsible If Product Damage Occurs Due To Local Indonesian Shipping. However, IMPORTIR.COM Will Assist The Complaint And Claim Process To The Relevant Expedition.",
                "If The Refund Is Due To The Number Of Products Received Not According To The Order Or Less When Received At The Destination Address, The Importer.com Team Will Help Make Complaints And Confirmations To The Supplier Because It Is Our Job As A Liaison Between Porters And Suppliers. After The Supplier Makes A Refund, A Refund To Your Friend Will Be Made Immediately.",
                "If The Refund Is Due To The Goods Being Held Or Lost At Customs, It Will Be The Full Responsibility Of IMPORTIR.COM.",
                "Complaint Not Acceptable If",
                "Not Sending And Making Proof Of Unboxing Videos And Photos Of The Complained Item.",
                "Make A Complaint After 3 Days Since The Goods Are Received At The Destination Address.",
                "The Unboxing Video Is Cut Or Paused Or Is A Combination Of Several Videos.",
                "The Video Does Not Show The Order Number And Receipt Attached To The Outside Of The Package.",
                "Damage Occurs During The Shipping Process Caused By The Expedition.",
                "Complaint Acceptable If",
                "Porters Send And Make Evidence Of Unboxing Videos And Photos Of Complained Items.",
                "Make A Complaint Before 3 Days Since The Goods Are Received At The Destination Address.",
                "The Video Created Is One Whole Video That Is Not Interrupted Or Cut And Not A Combination Of Several Videos.",
                "The Video Shows The Order Number And Receipt Attached To The Outside Of The Package.",
                "There Are Damages, Errors, Or Shortages In The Order When The Order Is Received At The Importir.com Warehouse And Is The Fault Of The IMPORTIR.COM Team.",
                "Force Majeure",
                "Importir.com Is Not Responsible If There Are Obstacles Or Obstacles Caused By Certain Circumstances That Are Beyond The Control Of The Importir.com Team, Which Are Unexpected Events, Including",
                "Lockdown Situation Due To Pandemic, Like What Happened During The Covid19 Event In China",
                "Fire, Explosion, Storm, Flood, Earthquake, Landslide, Plague And Other Natural Disasters",
                "Obstacles So That Trains, Shipping Planes, Or Other Means Of Transportation Cannot Be Used, Which Can Disrupt The Process Of Distributing Goods Or Products.",
                "Internet And Telecommunication Network Unusable",
                "Government Actions, Decrees, Laws, Regulations Or Restrictions, Such As Transportation Strikes Relating To Importation Processes",
                "Disturbance, Riot, Invasion, Terrorist Attack/threat, Attack/threat/Preparation For War",
                "If Force Majeure Occurs For More Than One Week, You Can Cancel The Order Through Written Notification And Make A Refund That Has Been Paid But The Process Of Forwarding Funds To The Supplier Has Not Been Carried Out."
            ],
            "refund_statuses": {
                "APPROVE ADMIN": "APPROVE ADMIN",
                "APPROVE SHIPPING": "APPROVE SHIPPING",
                "APPROVE CUSTOMER": "APPROVE CUSTOMER",
                "APPROVE FINANCE": "APPROVE FINANCE",
                "PUSH PLATFORM": "PUSH PLATFORM",
                "TRANSFERRED SUCCESS": "TRANSFERRED SUCCESS",
                "REJECT SHIPPING": "REJECT SHIPPING",
                "REJECT FINANCE": "REJECT FINANCE"
            },
            "refund_transaction_list": "Refund Transaction List",
            "remaining_cbm_balance": "Your Remaining CBM Balance",
            "remittence": "Remittence",
            "required": "Required",
            "reset": "Reset",
            "save": "Save",
            "sea": "SEA",
            "search": "Search",
            "search_by_image": "Search By Image",
            "search_pl_news_page": "Search By Title Or Description",
            "searching_product": "Searching Product",
            "second_bill_estimation": "Second Bill Estimation",
            "seconds": "Seconds",
            "see_all": "See All",
            "select_address_by_district_or_city": "Select Address based on District / City Input",
            "select_freight": "Select Freight",
            "select_placeholder": "-- Please Select --",
            "selected_product": "Selected Product",
            "sent_to": "Sent To",
            "service": "Services",
            "sfy_id": "Source for You ID",
            "sfy_info": "Source For You Is A Feature That Will Help IMPORTIR ORG / IMPORTIR COM Members To Get The Desired Products And Determine The Cost Of Goods Sold In The Easiest Way Possible.",
            "sfy_unavailable": "List Source For You Unavailable",
            "shipping_address": "Shipping Address",
            "shipping_by": "Shipping By",
            "shipping_detail": "Shipping Detail",
            "shipping_fee": "Shipping Fee",
            "shipping_fee_to": "Shipping Fee To {{country}}",
            "shipping_file": "Shipping File",
            "shipping_method": "Shipping Method",
            "shipping_number": "Shipping Number",
            "shipping_status": {
                "CUSTOMER READY": "CUSTOMER READY",
                "ADMIN CONFIRM": "ADMIN CONFIRM",
                "CUSTOMER PAID": "CUSTOMER PAID",
                "SUPPLIER PAID": "SUPPLIER PAID",
                "IN WAREHOUSE": "IN WAREHOUSE",
                "IN SHIPPING": "IN SHIPPING",
                "IN WAREHOUSE COUNTRY": "IN WAREHOUSE COUNTRY",
                "DONE": "DONE"
            },
            "shop_again": "Shop Again",
            "shop_empty": "Whoa, your shopping cart is empty",
            "shop_from": "Shop From",
            "show_detail": "Show Detail",
            "sign_out": "Sign Out",
            "simulator": "Simulator",
            "sold": "Sold",
            "start": "Start",
            "start_learning_today": "Start Learning Today",
            "start_on": "Start On",
            "start_tour": "Start Tour",
            "stock": "Stock",
            "submit": "Submit",
            "success_add_to_cart": "You Have Successfully Added The Product To Your Cart.",
            "summary": "Summary",
            "supplier": "Supplier",
            "supplier_bank_info": "Supplier Bank Information",
            "supplier_data": "Supplier Data",
            "supplier_email": "Supplier Email",
            "supplier_file": "Supplier File",
            "supplier_name": "Supplier Name",
            "supplier_paid_file": "Supplier Paid File",
            "supplier_phone": "Supplier Phone",
            "supplier_wechat": "Supplier Wechat ID",
            "tax_diff_info": "Tax Differences Are Determined Based On The Government Database",
            "term_condition": "Term & Condition",
            "thank_you": "Thank You",
            "title": "Title",
            "total": "Total",
            "total_price_including_tax": "Total Price Including Tax and Others.",
            "total_product_price": "Total Product Price",
            "tour_home_banner": "Here's The Banner Section To Let You Know What's Available",
            "tour_home_cart": "In This Section You Can See A List Of Items That You Have Put In Your Cart.",
            "tour_home_category": "This Category Section To Make It Easier For You To Find The Product You Want",
            "tour_home_crowdfund": "This Is The Crowdfund Section, In This Section You Will Get The Newest Crowdfund Product List",
            "tour_home_menu": "You Can Access To See What Menus Are Available",
            "tour_home_news": "This Is The News Section, In This Section You Will Get News About What Items Are Trending",
            "tour_home_profile": "Here You Can See The Profile, Order, Invoice, Contact Us And Log Out Menus",
            "tour_home_quick_menu": "This Is A Quick Menu Section To Make It Easier For You To Access The Menu",
            "tour_home_search_by_image": "You Can Search Products By Image",
            "tour_home_user_info": "User Name And User Package Information When Logged In",
            "tour_home_wishlist": "This Is The Wishlist Section, In This Section You Can See The Wishlist That You Have Saved",
            "tour_shipping_form_additional_fee": "In This Section You Can Add Additional Fees If There Are Any",
            "tour_shipping_form_address": "In This Section You Can Choose The Address You Will Use For Shipping",
            "tour_shipping_form_cbm_balance": "In This Section You Can Use The Cbm Balance That You Have.",
            "tour_shipping_form_country": "In This Section You Can Choose Whether You Want To Buy Goods From China Or Thailand And Which Will Later Adjust The Price Rates.",
            "tour_shipping_form_freight": "In This Section You Are Required To Fill In The Shipping Type, Freight, Estimated Volume, Estimated Weight, And You Can Also Add Wood Packing.",
            "tour_shipping_form_invoice": "In This Section You Can Enter Information About The Supplier Of The Item You Are Going To Buy.",
            "tour_shipping_form_others": "In This Section You Can Choose Local Delivery And You Can Also Leave A Note For The Admin.",
            "tour_shipping_form_product_cost": "In This Section You Can Enter The Product You Want To Buy.",
            "tour_shipping_form_promo": "In This Section You Can Enter The Promo Code You Have",
            "tour_shipping_form_summary": "In This Section You Can See Details Of The Estimated Price Of The Item You Are Going To Buy.",
            "tour_shipping_index_create_shipping": "This Button Is Used To Create A New Shipping Order",
            "tour_shipping_index_detail": "In This Section You Can See The Details Of Your Shipping Order.",
            "tour_shipping_index_old_order": "In This Section You Can View Your Old Order History.",
            "tour_shipping_index_simulator": "This Button Is Used To Navigate To The Shipping Simulator Page",
            "transaction_type": "Transaction Type",
            "transparent": "Transparent",
            "type": "Type",
            "typing_dont_match": "Your Typing Doesn't Match, Re-type Please",
            "unpaid": "Not Paid",
            "updated_at": "Updated At",
            "upload_supplier_info": "Upload Supplier Data For Faster Payment Processing",
            "use": "Use",
            "use_cbm_balance": "Use CBM Balance",
            "use_cbm_balance_from": "Use {{balance}} CBM From {{type}}",
            "use_voucher": "Use Voucher",
            "used_cbm_total": "Used CBM Balance Total",
            "user_information": "User Information",
            "va_number": "Virtual Account Number",
            "variant": "Variant",
            "video_not_found": "Video Not Found",
            "view": "View",
            "view_profile": "View Profile",
            "voucher_code": "Voucher Code",
            "voucher_list": "Voucher List",
            "voucher_order": "Voucher Order",
            "voucher_questions": "Voucher Questions",
            "waiting_for_payment": "Waiting for Payment",
            "want_to_add_address": "Want To Add New Address?",
            "warehouse_delivery_fee": "Warehouse Delivery Fee",
            "we_always_transparent": "We Are Always {{transparent}}",
            "weight": "Weight",
            "welcome_to_importir": "Welcome To Importir",
            "will_end_on": "Will End On",
            "wishlist": "Wishlist",
            "wooden_packing": "Wooden Packing",
            "wooden_packing_agreement": "If You Click YES, You Agree For Additional Fee For Wooden Frame 130RMB/box And When You Click YES It Can Not Be Cancelled. If You Agree, Please Proceed By Clicking",
            "wooden_packing_policy": "We Are Not Responsible For Any Damage Without Wooden Packing."
        }
    }
}
export default resLang;