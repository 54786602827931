/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Divider, Flex, Heading, HStack, Image, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent,  ModalOverlay, Select, SimpleGrid, Skeleton, Spacer, Spinner, Stack, Tag, Text, useToast, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { viewSingleProduct } from '../Api/importirApi';
import AppCarosel from '../Components/AppCarosel';
import ButtonQuantity from '../Components/Basic/ButtonQuantity';
import AuthContext from '../Routes/hooks/AuthContext';
import { getCountry } from '../Utils/country';
import { formatFrice, getHandlingFeePercentage, getShippingFee, stringToSlug } from '../Utils/Helper';
import { IoCaretBackOutline } from 'react-icons/io5'
import colors from '../Utils/colors';
import _axios from '../Api/AxiosBarrier'
import moment from 'moment';
import CountDown from '../Components/CountDown/CountDown';
import ConvertPrice from '../Utils/ConvertPrice';
import { BsCartCheck } from 'react-icons/bs';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';
import { db } from '../Config/firebase';
import { arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore';
import store from 'store'
import { errorSlack } from '../Api/SlackApi';
import _ from 'lodash'
import { checkProductValidity, checkProductValidityNoMember } from '../Routes/UserMiddleware';
import PaymentOrderNoMember from '../Components/Payment/PaymentOrderNoMember';
import { HiOutlineBellAlert } from 'react-icons/hi2'
import { sendEcommerceEvent } from '../Utils/gtm';
import { useTranslation } from 'react-i18next';
import { useJitsu } from '@jitsu/jitsu-react';
import { triggerEventFacebookPixel } from '../Utils/facebook';
function ProductDetailPage() {
	const {analytics} = useJitsu()
	const {t} = useTranslation();
	const { convertPriceFix } = ConvertPrice();
	const navigate = useNavigate()
	const param = useParams()
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const path = window.location.href ?? '#'
	const height = window.innerHeight
	const packageUser = store.get('user_package');
	const userPackage = store.get('package_user');
	const { loadingShow, loadingClose, userDb, getCart, userSymbolCurrency, currentUser, productListWishlistId, kursRates } = useContext(AuthContext)
	const [product, setProduct] = useState()
	const [images, setImages] = useState([])
	const [supplier, setSupplier] = useState('')
	const [variantType, setVariantType] = useState(null)
	const [priceType, setPriceType] = useState(null)
	const [variants, setVariant] = useState([])
	const [variantActive, setVariantActive] = useState(null)
	const [price, setPrice] = useState(0)
	const [priceRange, setPriceRange] = useState([])
	const [variantIndexActive, setIndexVariantActive] = useState(0)
	const [productPrice, setProductPrice] = useState(0)
	const [weight, setWeight] = useState('0')
	const [volume, setVolume] = useState('0')
	const [seaFee, setSeaFee] = useState(0)
	const [airFee, setAirFee] = useState(0)
	const [shipBy, setShipBy] = useState("");
	const [rateRmb, setRateRmb] = useState(0)
	const [totalOrder, setTotalOrder] = useState(0)
	const [hasOrder, setHasOrder] = useState([])
	const [loading, setLoading] = useState(false)
	const [orderNoMember, setOrderNoMember] = useState('')
	const [buyNowModal, setBuyNowModal] = useState(false)
	const [showModalNavigate, setShowModalNavigate] = useState(false)
	const userData = store.get('fb_account')
	useEffect(() => {
		const userDataLoc = store.get('user_api');
		if (userDataLoc === undefined) {
			if (["crowdfund_1688", "crowdfund_taobao"].includes(param?.type)) {
				// For iphone crowdund, it can be added to cart when member not logged in
				// if (param.id !== "1696395669") {
				// 	toast({
				// 		title: 'importir.com',
				// 		description: 'Please logged in first to add to cart this crowdfund product',
				// 		status: 'warning',
				// 		isClosable: true,
				// 		duration: 9000
				// 	});
				// 	return
				// }
				setShipBy("Air")
			}
		}

		if (userDataLoc?.country_code === "id"){
			if (moment().isAfter(moment(packageUser?.expired_at))){
				return navigate(`/product-free-member/${param?.type}/${param?.id}`);
			}
		}

		if (userDataLoc?.country_code === "ph" && ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type)) {
			toast({
				title: 'importir.com',
				description: 'This product is not available in your country',
				status: 'warning',
				isClosable: true,
				duration: 9000
			});
			return
		} else {
			if (param.type === "amazonjapan") {
				toast({
					title: 'importir.com',
					description: 'This Japanese product will be launch soon.',
					status: 'warning',
					isClosable: true,
					duration: 9000
				});
				return
			} else {
				initiateData()
			}
			return () => {
				setProduct()
				setImages([])
				setSupplier()
			}
		}
	}, [kursRates])

	const initiateData = async () => {
		const ratesData = await getDataRates()
		await getProduct(ratesData)
	}

	const getDataRates = async () => {
		try {
			const res = await _axios.get('api/rates')
			if (res.status) {
				setRateRmb(res.data.rmb)
			}
			return res.data
		} catch (error) {
			await errorSlack(error, 'importir.com', path)
		}
	}

	const getProduct = async (ratesData) => {
		try {
			const userDataLoc = store.get('user_api')
			loadingShow();
			if (param.id === null || param.id === undefined){
				return toast({
					title: 'importir.com',
					description: 'Product ID Is Invalid, Please Kindly Contact Our Customer Services For More Information',
					status: 'error',
					isClosable: true,
					duration: 9000
				});
			}
			const res = await viewSingleProduct(param.id, param.type);
			console.log(res, 'ini res product')
			loadingClose();
			if (res.status === true) {
				const data = res?.data;
				if (data?.platform_type === 'amazonus') {
					if (Number(data?.source === 'extension' ? data?.prices * kursRates?.rmb : data?.prices || 0) === 0) {
						toast({
							title: 'importir.com',
							description: 'This USA product price is 0, we are investigating this product.',
							status: 'warning',
							isClosable: true,
							duration: 9000
						});
						return
					}
				}
				if (userDataLoc === undefined) {
					if (["crowdfund_1688", "crowdfund_taobao"].includes(param?.type)) {
						if (data?.show_for === "member") {
							toast({
								title: 'importir.com',
								description: 'Please logged in first to add to cart this crowdfund product',
								status: 'warning',
								isClosable: true,
								duration: 9000
							});
							return
						}
					}
				} else {
					if (["crowdfund_1688", "crowdfund_taobao"].includes(param?.type)) {
						if (data?.show_for === "non_member") {
							toast({
								title: 'importir.com',
								description: 'The crowdfund product is only for non member / non logged in',
								status: 'warning',
								isClosable: true,
								duration: 9000
							});
							return
						}
					}
				}
				const variants = initVariant(data?.variants);
				console.log(kursRates, 'ini kurs rates')
				console.log(data, 'ini kurs rates')
				setImages(param.type === "amazonus" ? [data?.main_image] : data?.product_images?.map((x) => x.link));
				setProduct(data);
				setSupplier(data.supplier ? data.supplier.name : '');
				setVariantType(data?.variant_type);
				setVariant(variants);
				setPriceType(data?.price_type);
				setPrice(Number(data?.source === 'extension' ? data?.prices * kursRates?.rmb : data?.prices || 0));
				setPriceRange(data?.price_ranges || 'no_variant');
				setAirAndSeaByFromCountry(data, ratesData);
				if (variants.length) {
					setVariantActive(variants[0]);
				}
				const ecommerceData = {
					'user_id': userData?.user?.uid ?? "",
					'location': `${window.location.pathname}?slug=${stringToSlug(data?.title)}&category=${stringToSlug(data?.category?.name)}`,
					'email': userDataLoc?.email,
					'company_id': "dNNHm2zy4iTSfp1ejV9K",
					'project_id': "wjS0AhuP4FAF1j0A6tb9",
					'currency': 'IDR',
					'value': Number(data?.source === 'extension' ? data?.prices * kursRates?.rmb : data?.prices || 0),
					'items': JSON.stringify([
						{
							'item_id': data?.id,
							'item_name': data?.title,
							'item_category': data?.category,
						},
					]),
				};
				analytics.track('ViewItem', ecommerceData);
				triggerEventFacebookPixel('ViewContent', ecommerceData)
				// sendEcommerceEvent('view_item', ecommerceData);
			} else {
				toast({
					title: 'importir.com',
					description: res.message,
					status: 'error',
				});
			}
		} catch (error) {
			toast({
				title: 'importir.com',
				description: error.message,
				status: 'error',
			});
			const res = await errorSlack(error, 'importir.com', path);
			console.log(res, 'CONNECTED ERROR IN SLACK');
		} finally {
			loadingClose();
		}
	};

	const initVariant = (variant) => {
		return variant.map((x) => {
			x.order = '0'
			x.variant_items = x.variant_items
				.map((y) => {
					y.order = '0'
					return y
				})
			return x
		})
	}

	const setAirAndSeaByFromCountry = (data, ratesData) => {
		const userDataLoc = store.get('user_api');
		const seaFee = getShippingFee(userPackage?.package?.name, 'sea', !data?.is_lartas ? 0 : 1, userDataLoc?.country_code)
		const airFee = getShippingFee(userPackage?.package?.name, 'air', !data?.is_lartas ? 0 : 1, userDataLoc?.country_code)
		setSeaFee(seaFee);
		setAirFee(airFee);
		if (userDataLoc?.country_code === 'id') {
			if (data?.platform_type === 'amazonjapan') {
				setAirFee(330000)
			} else if (data?.platform_type === 'amazonus') {
				setAirFee(320000)
			} else if (data?.platform_type === 'coupang') {
				setAirFee(195000)
			}
		} else if (userDataLoc?.country_code === 'ph') {
			setSeaFee(11500 * parseFloat(ratesData.php))
			setAirFee(780 * parseFloat(ratesData.php))
		}

		if (data?.platform_type === 'amazonjapan') {
			setAirFee(330000)
		} else if (data?.platform_type === 'amazonus') {		
			setAirFee(320000)
		} else if (data?.platform_type === 'coupang') {
			setAirFee(195000)
		}
	}

	const showTagMarketplace = (type = '') => {
		if (type === "amazonjapan") return "Amazon Japan"
		if (type === "amazonus") return "Amazon US"
		return type
	}

	const showCountryByPlatformType = (type = '') => {
		if (type === "amazonjapan") return "Japan"
		if (type === "amazonus") return "USA"
		if (type === "coupang") return "Korea"
		return "China"
	}

	const handleWishlist = async (data) => {
		const dataWishlist = {
			category: data.category.id,
			category_title: data.category.name,
			createdAt: new Date(),
			createdAt_wishlist: new Date(),
			date: moment(new Date()).valueOf(),
			id: data.product_id.toString(),
			image: data.main_image,
			link: "https://detail.1688.com/offer/670512294734.html?&object_id=670512294734&scm2=1007.30657.177495.0&pvid2=f50f9230-8f5a-490b-85c2-43b23d56fe82",
			price: Number(data?.source === 'extension' ? data?.prices * kursRates?.rmb : data?.prices || 0) / rateRmb,
			sold: "30天成交0.1万件",
			title: "短袖v领修身大码连衣裙",
			"title-en": { en: data.title }
		};

		if (userDb) {
			loadingShow();

			const dataId = data?.product_id;

			if (dataId !== undefined) {
				const docRef = doc(db, "products", dataId.toString());
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const productData = docSnap.data();
					let newData = productData;
					newData.createdAt_wishlist = new Date();
					newData.category_title = productData?.category;

					try {
						const wishlistRef = doc(db, "wishlist", currentUser.uid);

						await setDoc(wishlistRef, {
							uid: currentUser.uid,
							data: arrayUnion(newData),
							createdAt: new Date()
						}, { merge: true });

						try {
							await updateDoc(docRef, {
								wishlist_count: increment(1)
							});


							const ecommerceData = {
								'user_id': userData?.user?.uid ?? "",
								'email': userData?.user?.email ?? "",
								'location': window.location.pathname,
								'company_id': "dNNHm2zy4iTSfp1ejV9K",
								'project_id': "wjS0AhuP4FAF1j0A6tb9",
								'currency': 'IDR',
								'value': Number(data?.source === 'extension' ? data?.prices * kursRates?.rmb : data?.prices || 0),
								'items': JSON.stringify([
									{
										'item_id': data?.id,
										'item_name': data?.title,
										'item_category': data?.category,
										// 'price': parseFloat(data.price),
										// 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
									},
								]),
							};
							analytics.track('AddToWishlist', ecommerceData)
							// sendEcommerceEvent('add_to_wishlist', ecommerceData);

						} catch (error) {
							console.log(error);
							toast({
								title: 'importir.com',
								description: error.message,
								status: 'error'
							});

							const resError = await errorSlack(error, 'importir.com', path);
							console.log(resError, 'CONNECTED ERROR IN SLACK');
							console.log(error, 'ini error');
						}

						toast({
							title: 'importir.com',
							description: 'Successfully added the product to the wishlist.',
							status: 'success'
						});

						loadingClose();
					} catch (error) {
						toast({
							title: 'importir.com',
							description: error.message,
							status: 'error'
						});

						const resError = await errorSlack(error, 'importir.com', path);
						console.log(resError, 'CONNECTED ERROR IN SLACK');
						console.log(error, 'ini error');

						loadingClose();
					}
				} else {
					const wishlistRef = doc(db, "wishlist", currentUser.uid);

					await setDoc(wishlistRef, {
						uid: currentUser.uid,
						data: arrayUnion(dataWishlist),
						createdAt: new Date()
					}, { merge: true });

					toast({
						title: 'importir.com',
						description: 'Successfully added the product to the wishlist.',
						status: 'success'
					});

					loadingClose();
				}
			}

			loadingClose();
		} else {
			toast({
				title: 'importir.com',
				description: 'You must login first.',
				status: 'warning'
			});
		}
	};

	const getTitleTimerValue = (startDate, endDate) => {
		if (startDate === undefined) {
			return false
		}
		const now = moment()
		const start = moment(startDate)
		const end = moment(endDate)
		if (now.isBefore(start)) {
			return false
		}
		else if (now < end) {
			return true
		}
		else {
			return false
		}

	}

	const getTitleTimer = (startDate, endDate) => {
		if (startDate === undefined) {
			return t('crowdfund_has_ended');
		}
		const now = moment()
		const start = moment(startDate)
		const end = moment(endDate)
		if (now.isBefore(start)) {
			return t('start_on')
		}
		else if (now < end) {
			return t('will_end_on')
		}
		else {
			return t('crowdfund_has_ended');
		}

	}

	const handlingFeeByProductPrice = (product) => {
		const userDataLoc = store.get('user_api');
		return getHandlingFeePercentage(product, userPackage?.package?.name, shipBy, product?.is_lartas, 'com', userDataLoc?.country_code);
	}

	const renderFirstBill = () => {
		const userDataLoc = store.get('user_api');
		let total = 0;
		if (totalOrder > 0) {
			total = productPrice;
		} else {
			total = productPrice;
		}
		let handlingFee = 0
		let ppnHandlingFee = 11;

		let handlingFeeCalculate = 0;
		let ppnHandlingFeeCalculate = 0

		if (["crowdfund_1688", "crowdfund_taobao"].includes(product?.platform_type)) {
			handlingFee = 0;
			ppnHandlingFee = 0;
		} else {
			if (userPackage?.package_id === 1) {
				handlingFee = 10
			} else {
				handlingFee = 3
			}
		}

		if (userDataLoc?.country_code === "id") {
			if (!["crowdfund_1688", "crowdfund_taobao"].includes(product?.platform_type)) {
				if (userPackage?.package_id === 8) {
					handlingFee = handlingFeeByProductPrice(productPrice)
				} else {
					if (product?.is_lartas === 0) {
						handlingFee = handlingFeeByProductPrice(productPrice)
					} else {
						handlingFee = 3
					}
				}
			}
		}else{
			handlingFee = handlingFeeByProductPrice(productPrice)
		}

		if (userDataLoc === undefined) {
			handlingFee = 10;
			if (["crowdfund_1688", "crowdfund_taobao"].includes(product?.platform_type)) {
				handlingFee = 0;
			}
		}

		handlingFeeCalculate = (productPrice * handlingFee) / 100
		ppnHandlingFeeCalculate = ppnHandlingFee * handlingFeeCalculate / 100;
		const totalFirst =
			parseFloat(total) +
			parseFloat(handlingFeeCalculate) +
			parseFloat(ppnHandlingFeeCalculate);

		return (
			<Stack p={5} borderRadius="md" shadow="md" h="250px" spacing={2} bgColor="white">
				<Text my={1} fontWeight="bold" fontSize={16}>
					{t("first_bill_estimation")}
				</Text>

				<HStack>
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{t("product_price")} x {totalOrder}
					</Text>
					<Spacer />
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{userSymbolCurrency} {formatFrice(convertPriceFix(productPrice))}
					</Text>
				</HStack>

				<Divider />

				<HStack>
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{t("warehouse_delivery_fee")}
					</Text>
					<Spacer />
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{userSymbolCurrency} 0
					</Text>
				</HStack>

				<Divider />


				<HStack>
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{t("handling_fee")} {handlingFee}%
					</Text>
					<Spacer />
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{userSymbolCurrency} {formatFrice(convertPriceFix(handlingFeeCalculate))}
					</Text>
				</HStack>

				<Divider />


				<HStack>
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{t("ppn_handling_fee")}
					</Text>
					<Spacer />
					<Text color="coolGray.600" fontSize="xs" _dark={{ color: "warmGray.200" }}>
						{userSymbolCurrency} {formatFrice(convertPriceFix(ppnHandlingFeeCalculate))}
					</Text>
				</HStack>

				<Divider />


				<HStack my={1}>
					<Text fontWeight="bold">{t("total")}</Text>
					<Spacer />
					<Text fontWeight="bold">
						{userSymbolCurrency} {formatFrice(convertPriceFix(totalFirst))}
					</Text>
				</HStack>

				<Divider />
			</Stack>
		);
	};

	const findWeight = () => {
		const weightEst = weight ? weight : 0;
		const cbmEst = volume ? volume : 0;
		const w = (parseFloat(weightEst) / 1000) * totalOrder;
		const cbm = parseFloat(cbmEst) * totalOrder;
		let cbmToKg = 0;
		if (shipBy === "Sea") {
			cbmToKg = cbm * 168;
		} else {
			cbmToKg = cbm * 168;
			if (cbmToKg < w) {
				cbmToKg = w;
			}
		}
		return cbmToKg;
	}

	const generateProductPrice = (quantity) => {
		if (quantity <= 0) return setProductPrice(0)

		if (priceType === 'RANGE') {
			let initPrice = _.orderBy(product?.price_ranges, 'begin', 'desc').find((x) => {
				return quantity >= x.begin;
			})

			if (typeof initPrice === 'undefined') {
				initPrice = product?.price_ranges[product?.price_ranges.length - 1];
			}

			setPrice(Number(product?.source === 'extension' ? initPrice?.price * kursRates?.rmb : initPrice?.price || 0));
			const totalPrice = Number(product?.source === 'extension' ? initPrice?.price * kursRates?.rmb : initPrice?.price || 0) * quantity;
			setProductPrice(totalPrice);
		} else if (priceType === 'PRICE_BY_VARIANTS' || priceType === 'FIX') {
			const sumPrice = hasOrder
				.map((x) => Number(product?.source === 'extension' ? x?.price * kursRates?.rmb : x?.price || 0) * parseInt(x.order))
				.reduce((prev, next) => prev + next, 0)
			setProductPrice(sumPrice)
		}
	}

	const onAddQuantity = (types, index) => {
		if (typeof types === "boolean" && variantType) {
			console.log(variantType, 'ini variant type')
			if (['multiple_items', 'single_items'].includes(variantType)) {
				const variantAct = variantActive
				const item = variantAct.variant_items[index]
				if (types) {
					if (item.stock === item.order) {
						item.order
					} else {
						item.order = parseInt(item.order) + 1
					}
				} else {
					if (item.order <= 0) {
						item.order = 0
					} else {
						item.order = parseInt(item.order) - 1
					}
				}
				setVariantActive({ ...variantAct })
				const vrt = variants
				vrt[variantIndexActive] = variantAct
				setVariant([...vrt])
				if (item.order > 0) {
					const o = hasOrder
					const findOrder = o.findIndex((x) => x.specId === item.specId)
					if (findOrder >= 0) {
						o[findOrder].order = parseInt(item.order)
					} else {
						o.push(item)
					}

					setHasOrder(o)
				}

				const tOrder = hasOrder
					.map((item) => parseInt(item.order))
					.reduce((prev, next) => prev + next, 0)
				setTotalOrder(tOrder)
				generateProductPrice(tOrder)
			} else if (variantType === 'no_variants') {
				let qty = totalOrder

				if (types) {
					qty = parseInt(qty) + 1
				} else {
					if (qty <= 0) {
						qty = 0
					} else {
						qty = parseInt(qty) - 1
					}
				}
				setTotalOrder(qty)
				generateProductPrice(qty)
			}
		} else {
			let value = types.target.value
			if (value === ''){
				value = 0;
			}
			if (variantType === 'multiple_items') {
				const variantAct = variantActive
				const item = variantAct.variant_items[index]
				if (value) {
					if (item.stock === item.order) {
						item.order
					} else {
						item.order = parseInt(value)
					}
				} else {
					if (item.order <= 0) {
						item.order = 0
					} else {
						item.order = parseInt(value)
					}
				}
				setVariantActive({ ...variantAct })
				const vrt = variants
				vrt[variantIndexActive] = variantAct
				setVariant([...vrt])
				if (item.order > 0) {
					const o = hasOrder
					const findOrder = o.findIndex((x) => x.specId === item.specId)
					if (findOrder >= 0) {
						o[findOrder].order = parseInt(item.order)
					} else {
						o.push(item)
					}

					setHasOrder(o)
				}

				const tOrder = hasOrder
					.map((item) => parseInt(item.order))
					.reduce((prev, next) => prev + next, 0);
				setTotalOrder(tOrder)
				generateProductPrice(tOrder)
			} else if (variantType === 'no_variants') {
				let qty = totalOrder

				if (value) {
					qty = parseInt(value)
				} else {
					if (qty <= 0) {
						qty = 0
					} else {
						qty = parseInt(value)
					}
				}
				setTotalOrder(qty)
				generateProductPrice(qty)
			}
		}

	}
	const renderVariant = () => {
		// if(variants?.length === 0) return
		return variants?.map((x, index) => {
			return (
				x?.image?.includes('http') ?
					<Image
						key={index}
						src={
							x?.image
						}
						p="1"
						cursor='pointer'
						bg={variantIndexActive === index ? 'green.400' : 'gray.100'}
						alt="Alternate Text"
						width={'70px'}
						onClick={() => onSetVariant(index)}
					/>
					:
					<Button p={5} m={2} bgColor={'blackAlpha.100'}
						onClick={() => onSetVariant(index)}>{x?.image}</Button>
			)
		})
	}

	const renderVariantItem = () => {
		if (!variantActive) return
		const children = () => {
			if (variantActive?.variant_items?.length)
				return variantActive?.variant_items?.map((x, index) => (
					<Stack key={index}>
						<HStack space={2} w='100%' justifyContent="center" px="2" key={x?.skuId}>
							<HStack w={'65%'}>
								{
									x?.image_parent !== undefined ?
										x?.image_parent?.includes('http') ?
											<Image
												src={x?.image_parent}
												alt="image"
												width={'60px'}
											/>
											: ""
									: ""
								}
								<Stack rounded="md" >
									<Text fontSize="xs" lineHeight="sm" letterSpacing="sm">
										{x?.title}
									</Text>

									<Text fontSize="xs" color="gray.800">
										{userSymbolCurrency} {formatFrice(convertPriceFix(Number(product?.source === 'extension' ? x?.price * kursRates?.rmb : x?.price || 0)))}
									</Text>
								</Stack>
							</HStack>
							<Spacer />
							<Stack w={'30%'} alignItems="center">
								<Text fontSize="xs" color="black">
									{t("stock")} {x?.stock || x?.amountOnSale}
								</Text>
								{x?.stock || x?.amountOnSale > 0 ? <ButtonQuantity
									quantity={isNaN(x?.order) ? '0' : x?.order.toString()}
									pressButton={(arg) => onAddQuantity(arg, index)}
								/> : ''}

							</Stack>
						</HStack>
						<Divider />
					</Stack>

				))
			return <></>
		}

		return (
			<Stack borderRadius='md'>
				<HStack w={'100%'} overflowX='scroll' spacing={0}>
					{renderVariant()}
				</HStack>

				<Divider bg="gray.200" thickness="1" my="1.5" />
				<Box px="1">
					<Heading size="xs" color="yellow.600">
						{variantActive?.display_name || '-'}
					</Heading>
				</Box>
				<Stack maxH={'260px'} w='100%' overflowY='scroll'>
					<Stack>{children()}</Stack>
				</Stack>


			</Stack>
		)
	}

	const renderMultipleVariant = () => {
		if (['multiple_items', 'single_items']?.includes(variantType))
			return (
				<Box w='full'>{renderVariantItem()}</Box>

			)
	}

	const renderNoVariant = () => {
		if (variantType === 'no_variants')
			return (
				<Box bg="white" mb="5" p="4" width="100%" mx="auto" mt="5" borderColor="coolGray.200" borderWidth="1" >
					<Stack space={[1]} justifyContent="center" justifyItems="center">
						<Stack w={'50%'}>
							<Image src={images[0]
							} alt="Alternate Text" borderRadius={5} width={'70px'} />
						</Stack>
						<Spacer />
						<Box>
							<ButtonQuantity
								quantity={totalOrder.toString()}
								pressButton={(arg) => onAddQuantity(arg, null)}
							/>
						</Box>
					</Stack>
				</Box>
			)

		return <></>
	}

	const renderSecondBill = () => {
		let weightConverted = findWeight();
		let cbmConverted = weightConverted / 168;
		let feeAirAmazonJapan = airFee

		var Bm = 0;
		var PPn = 0;
		var PPh = 0;
		var bmPercentage = 7.5;
		var ppnPercentage = 11;
		var pphPercentage = 10;

		if (["1688", "taobao", "amazonjapan", 'amazonus', 'coupang'].includes(product?.platform_type)) {
			if (product) {
				if (product.hs_code) {
					const hsCode = product.hs_code
					if (!isNaN(Number(hsCode.mfn))) bmPercentage = hsCode.mfn
					if (!isNaN(Number(hsCode.ppn))) ppnPercentage = hsCode.ppn
					if (!isNaN(Number(hsCode.pph_import))) pphPercentage = hsCode.pph_import
				}
			}

			Bm = (productPrice * bmPercentage) / 100;
			PPn = ((Bm + productPrice) * ppnPercentage) / 100;
			PPh = ((Bm + productPrice) * pphPercentage) / 100;
		}

		let shipFeeTotal = 0;

		if (shipBy === "Sea") {
			if (cbmConverted < 0.2) {
				cbmConverted = 0.2;
			}
			if (["1688", "taobao", "amazonjapan", 'amazonus', 'coupang'].includes(product?.platform_type)) {
				shipFeeTotal = cbmConverted * seaFee;
			}
			if (userPackage?.package_id === 6 && product?.is_lartas === 0) {
				cbmConverted = 0;
			}
		} else {
			if (weightConverted < 1) {
				weightConverted = 1;
			}
			if (["1688", "taobao"].includes(product?.platform_type)) {
				shipFeeTotal = weightConverted * airFee;
			} else if (["amazonjapan"].includes(product?.platform_type)) {
				shipFeeTotal = weightConverted * feeAirAmazonJapan;
			} else if (["amazonus", 'coupang'].includes(product?.platform_type)) {
				shipFeeTotal = weightConverted * airFee;
			}
		}


		const totalSeconds = Bm + PPn + PPh;

		return (
			<Stack p={5} borderRadius="md" shadow="md" h="250px" spacing={2} bgColor="white">
				<Text my={1} fontWeight="bold" fontSize={16}>
					{t("second_bill_estimation")}
				</Text>
				<HStack>
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{t("bm")} ({bmPercentage}%)
					</Text>
					<Spacer />
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{userSymbolCurrency} {formatFrice(convertPriceFix(Bm))}
					</Text>
				</HStack>
				<Divider />
				<HStack>
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{t("ppn")} ({ppnPercentage}%)
					</Text>
					<Spacer />
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{userSymbolCurrency} {formatFrice(convertPriceFix(PPn))}
					</Text>
				</HStack>
				<Divider />
				<HStack>
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{t("pph")} ({pphPercentage}%)
					</Text>
					<Spacer />
					<Text
						color="coolGray.600"
						fontSize="xs"
						_dark={{
							color: "warmGray.200",
						}}
					>
						{userSymbolCurrency} {formatFrice(convertPriceFix(PPh))}
					</Text>
				</HStack>
				<Divider />
				{
					<HStack>
						<Text
							color="coolGray.600"
							fontSize="xs"
							_dark={{
								color: "warmGray.200",
							}}
						>
							{`${t("shipping_fee")} ${showCountryByPlatformType(product?.platform_type)} - ${userDb?.data_importir?.user?.country_code === "ph" ? "Philippines" : "Indonesia"} (${shipBy === "Air" ? weightConverted : cbmConverted} ${shipBy === "Air" ? " Kg" : " CBM"})`}

						</Text>
						<Spacer />
						<Text
							color="coolGray.600"
							fontSize="xs"
							_dark={{
								color: "warmGray.200",
							}}
						>
							{userSymbolCurrency}{" "}
							{formatFrice(convertPriceFix(shipFeeTotal))}
						</Text>
					</HStack>
				}

				<Divider />
				<HStack my={1}>
					<Text fontWeight="bold">{t("total")}</Text>
					<Spacer />
					<Text fontWeight="bold">
						{userSymbolCurrency}{" "}
						{totalOrder > 0
							? formatFrice(convertPriceFix(totalSeconds + shipFeeTotal))
							: formatFrice(convertPriceFix(totalSeconds + shipFeeTotal))}
					</Text>
				</HStack>
				<Divider />
			</Stack>
		);
	};

	const onSetVariant = (index) => {
		setVariantActive({ ...variants[index] })

		console.log({ ...variants[index] }, 'xxxx')
		setIndexVariantActive(index)
	}

	const allowShowOrderButton = () => {
		if (!(product?.platform_type)?.includes('crowdfund')) {
			return true
		}

		else {
			const title = getTitleTimer(product?.start_date, product?.end_date);
			if (title !== 'Product crowdfund has ended') {
				return true
			}
		}
	}

	const onAddModalBuyNow = () => {
		const paramVariant = hasOrder
			.filter((x) => x.order > 0)
			.map((x) => {
				return {
					quantity: x.order,
					specId: x.specId,
					skuId: typeof x.skuId != 'undefined' ? x.skuId : null,
					name: x.title,
					nameOri: x.titleOri
				}
			})

		let params = {
			variants: paramVariant,
			variant_type: variantType,
			price_type: priceType,
			quantity: totalOrder,
			freight: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? "Sea" : shipBy,
			product_id: product?.product_id,
			weight: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? product.weight : weight,
			cbm: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? product.cbm_per_product : volume,
		}

		const validationResult = checkProductValidityNoMember(param, shipBy, volume, weight, totalOrder, product);
		if (!validationResult.success) {
			toast(validationResult.error);
			return;
		}

		setOrderNoMember(params)
		setBuyNowModal(true)
	}

	const onAddToCart = () => {
		const paramVariant = hasOrder
			.filter((x) => x.order > 0)
			.map((x) => {
				return {
					quantity: x.order,
					specId: x.specId,
					skuId: typeof x.skuId != 'undefined' ? x.skuId : null,
					name: x.title ? x.title : x.titleOri,
					nameOri: x.titleOri ? x.titleOri : x.title
				}
			})

		let params = {
			variants: paramVariant,
			variant_type: variantType,
			price_type: priceType,
			quantity: totalOrder,
			freight: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? "Sea" : shipBy,
			product_id: product?.product_id,
			weight: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? product.weight : weight,
			cbm: ["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ? product.cbm_per_product : volume,
		}

		const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);
		const currentDate = new Date();

		const validationResult = checkProductValidity(userDb, param, shipBy, volume, weight, currentDate, expirationDate, totalOrder, product);
		if (!validationResult.success) {
			toast(validationResult.error);
			return;
		}

		return postCart(params)
	}

	const postCart = async (params) => {
		console.log("params", JSON.stringify(params))
		setLoading(true)
		try {
			const res = await _axios.post('api/carts', params)
			if (res.status === false) {
				toast({
					title: 'importir.com',
					description: res.message,
					status: 'error',
				})
				const resError = await errorSlack(res.message, 'importir.com', path)
				console.log(resError, 'CONNECTED ERROR IN SLACK')
				setLoading(false)
			} else {
				toast({
					title: 'importir.com',
					description: res.message,
					status: 'success',
				})

				const ecommerceData = {
					'user_id': userData?.user?.uid ?? "",
					'email': userData?.user?.email ?? "",
					'location': window.location.pathname,
					'company_id': "dNNHm2zy4iTSfp1ejV9K",
					'project_id': "wjS0AhuP4FAF1j0A6tb9",
					'currency': 'IDR',
					'value': Number(product?.source === 'extension' ? product?.prices * kursRates?.rmb : product?.prices || 0),
					'items': JSON.stringify([
						{
							'item_id': product?.id,
							'item_name': product?.title,
							'item_category': product?.category,
							// 'price': parseFloat(data.price),
							'quantity': totalOrder, // Jumlah produk yang ditambahkan ke keranjang
						},
					]),
				};
				analytics.track('AddToCart', ecommerceData);
				triggerEventFacebookPixel('AddToCart', ecommerceData)
				// sendEcommerceEvent('add_to_cart', ecommerceData);

				setShowModalNavigate(true)
				getCart(userDb)
				setLoading(false)
			}

		} catch (error) {
			console.log(error, 'ini error')
			const resError = await errorSlack(error, 'importir.com', path)
			console.log(resError, 'CONNECTED ERROR IN SLACK')
			setLoading(false)
		}
		setLoading(false)
	}

	return (
		<>
			{param.type === "amazonjapan" && (
				<Stack alignItems={'center'} justifyContent='center' h={height / 1.5}>
					<HiOutlineBellAlert size={70} color='gray' />
					<Text fontSize={'xl'} textAlign='center' fontWeight='bold' color={'blackAlpha.600'} >This Japanese product will be launch soon.</Text>
					<Text fontSize={'sm'} textAlign='center' color={'blackAlpha.600'} >IMPORTIR.COM</Text>
				</Stack>
			)}

			{!userDb && shipBy === "Sea" && (
				<Alert
					px={[2, 2, 5]}
					status="error"
					fontSize={{ base: "sm", md: "md" }}
					transition="all 0.3s ease-in-out"
				>
					<AlertIcon />
					<AlertTitle display={{ base: "none", md: "block" }}>
						Your website is not authenticated!
					</AlertTitle>
					<AlertDescription>
						<Stack direction={{ base: "column", md: "row" }} spacing={2}>
							<Text
								display={{ base: "inline", md: "none" }}
								fontStyle="italic"
								fontWeight="bold"
								_hover={{ textDecoration: "underline" }}
							>
								Your website is not authenticated!
							</Text>

							<HStack>
								<Text>You must login first to order the product. Click this option for</Text>
							</HStack>
							<Text
								fontStyle="italic"
								fontWeight="bold"
								color="blue.500"
								_hover={{ textDecoration: "underline" }}
								onClick={() => navigate("/register")}
							>
								Register for a new account
							</Text>

							<Text
								display={{ base: "none", md: "inline" }}
								mr={1}
							>
								or
							</Text>
							<Text
								fontStyle="italic"
								fontWeight="bold"
								color="blue.500"
								cursor="pointer"
								onClick={() => navigate("/login")}
								_hover={{ textDecoration: "underline" }}
							>
								Log in to your account
							</Text>
						</Stack>
					</AlertDescription>
				</Alert>
			)}

			{store.get('user_api')?.country_code === 'ph' && param.type === 'amazonus' ?
			 (
				<Stack alignItems={'center'} justifyContent='center' h={height / 1.5}>
					<HiOutlineBellAlert size={70} color='gray' />
					<Text fontSize={'xl'} textAlign='center' fontWeight='bold' color={'blackAlpha.600'} >This USA product is not available in your country</Text>
					<Text fontSize={'sm'} textAlign='center' color={'blackAlpha.600'} >IMPORTIR.COM</Text>
				</Stack>
			)
			:
				store.get('user_api')?.country_code === 'ph' && param.type === 'coupang'
				?
					(
						<Stack alignItems={'center'} justifyContent='center' h={height / 1.5}>
							<HiOutlineBellAlert size={70} color='gray' />
							<Text fontSize={'xl'} textAlign='center' fontWeight='bold' color={'blackAlpha.600'} >This korea product is not available in your country</Text>
							<Text fontSize={'sm'} textAlign='center' color={'blackAlpha.600'} >IMPORTIR.COM</Text>
						</Stack>
					)
				:
					<>
						<Stack p={[1, 1, 5]} bgColor='gray.100' mt={[10, 10, 0]}>
							<HStack position={'absolute'} cursor='pointer' zIndex='50' m={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
								<IoCaretBackOutline size={15} />
								<Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
							</HStack>
							<SimpleGrid columns={[1, null, 2]} gap={2}>
								<Stack maxW={'680px'}  overflow={'hidden'}>
									{images.length > 0 ? (
										<AppCarosel images={images && images} />
									) :
										(
											<Skeleton w={'full'} h={height / 1.5}></Skeleton>
										)
									}
								</Stack>
								<Stack>
									<Box mt="0.5">
										<Stack overflow="hidden" bg="white" borderRadius={'md'} shadow='md' width="100%">

											{
												!["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) && (
													product?.product_id && (
														productListWishlistId?.includes((product?.product_id).toString()) ? (
															<Stack align={'end'}>
																<HiHeart style={{ fontSize: 35, color: 'red' }} />
															</Stack>
														) : (
															<Stack align={'end'} onClick={() => handleWishlist(product)} cursor='pointer'>
																<HiOutlineHeart style={{ fontSize: 35, color: 'gray' }} />
															</Stack>
														)
													)
												)
											}
											<HStack p="2">
												<Stack space={2} >
													<Heading size="sm">
														{product ? product.title : ''}
													</Heading>
													<HStack spacing={2} alignItems='center' >
														<Text
															fontSize="sm"
															_light={{
																color: 'gray.500',
															}}
															_dark={{
																color: 'gray.400',
															}}
															fontWeight="500"
														>
															{t("supplier")} :
														</Text>
														<Text
															fontSize="sm"
															_light={{
																color: 'blue.500',
															}}
															_dark={{
																color: 'blue.400',
															}}
															fontWeight="500"
														>
															{supplier}
														</Text>

													</HStack>
													<HStack space={2}>
														{
															store.get('user_api')?.country_code === 'id'  || store.get('user_api') === undefined ?
																<Tag
																	bgColor={
																		product?.is_lartas > 0 ? 'red.300' : 'yellow.300'
																	}
																	size="sm"
																>
																	{product?.is_lartas > 0 ? "Lartas" : "Non Lartas"}
																</Tag>
																: ""
														}

														{product?.platform_type && (
															getCountry(product?.platform_type)
														)}

														<Tag bgColor={'orange.300'} size={'sm'} >
															{showTagMarketplace(product?.platform_type)}
														</Tag>
													</HStack>
												</Stack>
												<Spacer />
											</HStack>
										</Stack>
									</Box>

									{
										["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ?
											<Box p="4" overflow="hidden"
												bg="white"
												width="100%">

												<Heading size="md" ml="-1" mb="4">
													<Text color={getTitleTimer(product?.start_date, product?.end_date) === 'Product crowdfund has ended' ? colors.danger : colors.dark}>
														{getTitleTimer(product?.start_date, product?.end_date)}
													</Text>
												</Heading>
												{getTitleTimer(product?.start_date, product?.end_date) !== 'Product crowdfund has ended' ?
													<CountDown startDate={product?.start_date} endDate={product?.end_date} />
													: false
												}
											</Box>
											: ""
									}

									{/* <Stack pt={2}>
										<Stack p={2} bgColor='white' space={2} shadow='md' borderRadius={'md'}>
											<Heading size={'md'}> Variants</Heading>
											<HStack
												w={'100%'}
												overflowX="scroll"
											>
												{variantActive && (
													variants.length > 0 && (
														variants.map((x, index) => (
															x?.image.includes('http') ?
																<Image
																	src={
																		x?.image
																	}
																	alt="Alternate Text"
																	w={'100px'}
																	h='100px'
																	key={index}
																	cursor={'pointer'}
																	onClick={() => handleOpenImage(x?.image)}
																/>
																:
																<Button bgColor={'blackAlpha.100'} p={5} m={2}>{x?.image}</Button>
														))
													)
												)}
											</HStack>
										</Stack>
									</Stack> */}

									<Box>
										<Box overflow="hidden" bg="white" width="100% " shadow='md' borderRadius={'md'}>
											<Stack p="4" space={2}>
												<Stack space={2}>
													<Heading size="md" ml="-1" mb="1">
														{t('price')}
													</Heading>
												</Stack>
												{
													priceType === 'RANGE' ?
														<HStack alignItems="center" safeAreaBottom shadow={6}>
															{priceRange.map((x, index) => {
																return (
																	<Stack flex={1} py="2" key={index} alignItems='center' justifyContent={'center'}>
																		<Box py="1">
																			<Text color="gray.700" fontSize="12">
																				{x.begin}
																			</Text>
																		</Box>
																		<Divider bg="gray.200" thickness="1" my="1.5" />
																		<Box py="1">
																			<Text color="red.700" fontWeight="bold" fontSize="12">
																				{userSymbolCurrency} {formatFrice(convertPriceFix(Number(product?.source === 'extension' ? x?.price * kursRates?.rmb : x?.price || 0)))}
																			</Text>
																		</Box>
																	</Stack>
																)
															})}
														</HStack>
														:
														priceType === 'FIX' ? (
															<Box>
																<Heading size="sm" color="red.700">
																	{userSymbolCurrency} {formatFrice(convertPriceFix(price))}
																</Heading>
															</Box>
														) :
															priceType === 'PRICE_BY_VARIANTS' ? (
																<Box>
																	<Heading size="sm" color="red.700">
																		{`${t('start')} ${t('from')}`} {userSymbolCurrency} {formatFrice(convertPriceFix(price))}
																	</Heading>
																</Box>
															) : (
																<></>
															)
												}
											</Stack>
										</Box>
									</Box>
									<Box mt="2">
										<Box overflow="hidden" bg="white" width="100%" shadow='md' borderRadius={'md'}>
											<Stack p="4" space={3}>
												<Stack space={2} h={'120px'}>
													<Heading size="md" ml="-1" mb="1">
														{t('product_info')}
													</Heading>

													<Divider bg="gray.200" thickness="1" my="1.5" />

													<Text>{t('moq')}: {product ? product?.moq : '-'}</Text>
													<Divider bg="gray.200" thickness="1" my="1.5" />
													<Text>{t('supplier_name')}: {supplier ? supplier : '-'}</Text>
												</Stack>
											</Stack>
										</Box>
									</Box>
									<Box mt="2"> 
										<Box overflow="hidden" bg="white" width="100%" shadow='md' borderRadius={'md'} p={3}>
											
											<Heading size={'md'} mb={2}>{t("form")}</Heading>
											<VStack spacing={2}>
												{["crowdfund_1688", "crowdfund_taobao"].includes(param?.type) ?
													store.get('user_api') === undefined ?
														<InputGroup size={'sm'}>
															<InputLeftAddon>
																{t('select_freight')}
															</InputLeftAddon>
															<Select
																w={'100%'}
																// size={'sm'}
																fontSize='xs'
																bgColor={'white'}
																accessibilityLabel={`${t("shipping_by")}`}
																isDisabled={true}
																value="Air"
																onChange={(e) => setShipBy(e.target.value)}
															>
																<option value="Air">{t('air')} {userSymbolCurrency} {formatFrice(convertPriceFix(airFee))}/Kg</option>
															</Select>
														</InputGroup>
														:
														<InputGroup size={'sm'}>
															<InputLeftAddon>
																{t('select_freight')}
															</InputLeftAddon>
															<Select
																w={'100%'}
																// size={'sm'}
																fontSize='xs'
																bgColor={'white'}
																accessibilityLabel={`${t("shipping_by")}`}
																placeholder={
																	userDb?.data_importir?.user?.country_code === "id"
																		? `${t("sea")} ${userSymbolCurrency} ${formatFrice(convertPriceFix(seaFee))}/CBM`
																		: `${t("sea")}`
																}
																isDisabled={true}
																value="Sea"
																onChange={(e) => setShipBy(e.target.value)}
															>
																<option value="Sea">
																	{userDb?.data_importir?.user?.country_code === "id"
																		? `${t("sea")} ${userSymbolCurrency} ${formatFrice(convertPriceFix(seaFee))}/CBM`
																		: `${t("sea")}`}
																</option>
															</Select>
														</InputGroup>

													:
													<InputGroup size={'sm'}>
														<InputLeftAddon>
															{t('select_freight')}
														</InputLeftAddon>
														<Select
																w={'100%'}
															// size={'sm'}
															fontSize='xs'
															bgColor={'white'}
															accessibilityLabel={`${t("shipping_by")}`}
															placeholder={`${t("shipping_by")}`}
															onChange={(e) => setShipBy(e.target.value)}
														>
															<option value="Air">
																{`${t("air")} ${userSymbolCurrency} ${formatFrice(convertPriceFix(airFee))}/Kg`}
															</option>
															{
																!["amazonjapan", "amazonus", 'coupang'].includes(param?.type) ?
																	store.get('user_api') !== undefined ?
																		<option value="Sea">
																			{`${t('sea')} ${userSymbolCurrency} ${formatFrice(convertPriceFix(seaFee))}/CBM`}
																		</option>
																	: ""
																: ""
															}
														</Select>
													</InputGroup>}

													<SimpleGrid columns={[1, 2]} gap={2} w={'100%'}>
														<InputGroup size={'sm'}>
															<InputLeftAddon>
																{t('input_weight')}
															</InputLeftAddon>
															<Input 
																w={'100%'} isDisabled={param.type === "crowdfund_1688" ? true : false} placeholder={`${t("weight")} Gram/pcs`} type={'number'} onChange={(e) => setWeight(e.target.value)} />
														</InputGroup>
														<InputGroup size={'sm'}>
															<InputLeftAddon>
																{t('input_cbm')}
															</InputLeftAddon>
															<Input
																w={'100%'} isDisabled={param.type === "crowdfund_1688" ? true : false} placeholder="Volume CBM/pcs" type={'number'} onChange={(e) => setVolume(e.target.value)} />
														</InputGroup>
													</SimpleGrid>
											
											</VStack>
										</Box>
									</Box>
								</Stack>
							</SimpleGrid>
							<SimpleGrid columns={[1, null, 2]} gap={2}>
								<Stack display={{base: 'none', md: 'block'}}>
									{renderFirstBill()}
									{renderSecondBill()}
								</Stack>
								<Stack >
									<Stack p={5} borderRadius="md" h={{base: "520px", md: "505px"}} shadow="md" spacing={1} bgColor="white" >
										
										<Heading size={'md'}>{t("variant")}</Heading>
										<Stack>
											{renderMultipleVariant()}
											{renderNoVariant()}
										</Stack>
										<SimpleGrid columns={[1, 1]} bg="white" alignItems="center" w={'100%'}>
											<Flex justifyContent="space-between">
												<Text fontSize="sm" fontWeight="bold" color="gray.600">
													{`${t("total")} ${t("quantity")}`} : {totalOrder}
												</Text>
												<Text fontSize="sm" fontWeight="bold" color="green.600">
													{`${t("total")} ${t("price")}`} : {userSymbolCurrency} {formatFrice(convertPriceFix(productPrice))}
												</Text>
											</Flex>
											<Stack>
												{product && !allowShowOrderButton() ? (
													false
												) : (
													loading ? (
														<Stack alignItems={'center'} justifyContent='center'>
															<Spinner size={'sm'} />
														</Stack>
													) : (
														// <Text>{product?.platform_type}</Text>
														product?.platform_type?.includes('crowdfund') ?
															getTitleTimerValue(product?.start_date, product?.end_date) ? 
																<Button shadow={'md'} fontSize={'sm'} width="100%" onClick={() => onAddToCart()} bg="green.400">
																	<HStack alignItems={'center'} justifyContent='center' space={2}>
																		<Text color="gray.100" fontWeight='bold'>{t('add_to_cart')} 🛒</Text>
																	</HStack>
																</Button>
															: 
																<Text color={colors.danger}>
																	{getTitleTimer(product?.start_date, product?.end_date)}
																</Text>
														:
															<Button shadow={'md'} fontSize={'sm'} width="100%" onClick={!userDb && shipBy === "Air" ? () => onAddModalBuyNow() : () => onAddToCart()} bg="green.400">
																<HStack alignItems={'center'} justifyContent='center' space={2}>
																	<Text color="gray.100" fontWeight='bold'>{t('add_to_cart')} 🛒</Text>
																</HStack>
															</Button>
													)
												)}
											</Stack>
										</SimpleGrid>
									</Stack>
								</Stack>
							</SimpleGrid>
							<Stack display={{base: 'block', md: 'none'}}>
								{renderFirstBill()}
								{renderSecondBill()}
							</Stack>
							<Box mt='2' bgColor='white'>
								<Heading m='2' p='1' size={'md'}>{t("description")}</Heading>
								<Stack alignItems={'center'} justifyContent='center' maxW={'100%'} overflow='auto' p={10}>
									<div dangerouslySetInnerHTML={{ __html: product?.description }} />
								</Stack>
							</Box>
						</Stack>


						<Modal transition={'all 0.3s ease-in-out'} isOpen={showModalNavigate} onClose={() => setShowModalNavigate(false)} >
							<ModalOverlay />
							<ModalContent >
								<ModalCloseButton />

								<ModalBody >

									<Stack p={5} alignItems='center' justifyContent={'center'} spacing={7}>

										<Box>
											<BsCartCheck color='green' size={130} />
										</Box>
										<Stack>
											<Text fontSize={'lg'} textAlign='center'>{t('success_add_to_cart')}</Text>
										</Stack>

										<HStack spacing={5} >


											<Button onClick={() => navigate(-1)} size={'md'} fontSize='sm' bgColor={'#ffd600'} color='black' shadow={'md'}>
												<Text>{t('shop_again')}</Text>
											</Button>
											<Button onClick={() => navigate('/cart')} size={'md'} fontSize='sm' bgColor={'green.400'} color='white' shadow={'md'}>
												<Text>{t('proceed_to_cart')}</Text>
											</Button>
										</HStack>
									</Stack>
								</ModalBody>
							</ModalContent>
						</Modal>

						{orderNoMember && (
							<Modal size="xl" transition={'all 0.3s ease-in-out'} isOpen={buyNowModal} onClose={() => setBuyNowModal(false)} scrollBehavior="inside" isCentered>
								<ModalOverlay />
								<ModalContent >
									<ModalCloseButton />

									<ModalBody >
										<PaymentOrderNoMember data={orderNoMember} is_lartas={product?.is_lartas} platform={product?.platform_type} />
									</ModalBody>
								</ModalContent>
							</Modal>
						)}
					</>
			}

		</>
	)
}

export default ProductDetailPage